import { AxiosRequestConfig } from "axios";
import { Column } from "entities/table-component/model/state";
import { RequestStatus } from "shared/models/model";

export interface IHeaderTable {
  name: string;
  originName: string;
  hide?: boolean;
}

export interface TableDataModel {
  total: number;
  data: any[];
  footer_data: FooterData;
}

export interface FooterData {
  total_amount: number;
}


export interface ITableState {
  isInit: boolean;
  rows: any[];
  rowsPerPage: number;
  service: string;
  table: string;
  countRows: number;
  footerData: FooterData;
  lowestPrice: number;
  highestPrice: number;
  sumOfPayments: string;
  sumOfPaymentsOnPage: string;
  sourceUrl: string;
  header: Column[];
  hiddenHeader: Column[];
  requestStatus: RequestStatus;
  isLoadingTableGlobal: boolean;
  isNextPageTableDataEmpty: boolean;
  dataUrl: string;
  requestConfig: AxiosRequestConfig | null;
}

interface ITableSlice {
  [id: string]: ITableState;
}

export const initialTableState: ITableState = {
  isInit: false,
  rows: [],
  rowsPerPage: 10,
  service: '',
  table: '',
  footerData: {} as FooterData,
  countRows: 0,
  highestPrice: 0,
  lowestPrice: 0,
  sumOfPayments: '',
  sumOfPaymentsOnPage: '',
  sourceUrl: '',
  header: [],
  hiddenHeader: [],
  requestStatus: {
    error: '',
    isLoading: false,
    success: false,
  },
  isLoadingTableGlobal: false,
  isNextPageTableDataEmpty: false,
  dataUrl: '',
  requestConfig: null
}



export const initialTableSliceState: ITableSlice = {};

