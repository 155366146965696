import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import React, { memo } from "react";

import { Avatar, Stack } from '@mui/material';
import BackdropLoader from 'shared/ui/backdrop-loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from 'entities/user';
import { useAuth } from 'entities/auth';
import { useApp } from 'entities/app';
import { DraweMenuItem, Drawer, DrawerHeader, DrawerMenuItemSkeleton, OptionDrawerMenuItem } from './ui';
import ConditionModal from "shared/ui/condition-modal";
import { useDrawerMainMenu } from 'entities/main-menu-component';

import { usePageComponent } from 'entities/page-component';
import { useFilter } from 'entities/filter';
import { logoSxProps } from './props/logo';


import { ReactComponent as LogoIcon } from "shared/assets/logo.svg";
import { ReactComponent as LogoIconShort } from "shared/assets/logo-short.svg";
import { ReactComponent as ExitToAppIcon } from "shared/assets/icon_exit.svg";


interface MiniDrawerProps {
  open: boolean;
  toogleExpandDrawer: () => void
}

const MiniDrawer:React.FC<MiniDrawerProps>  = ({
  open,
  toogleExpandDrawer
}) => {
  
  // const [selectedDrawerItem, setSelectedDrawerItem] = React.useState(localStorage.getItem(''))
  const navigation = useNavigate();
  const location = useLocation();
  const user = useUser();
  const auth = useAuth();
  const app = useApp();
  const drawerMainMenu = useDrawerMainMenu();
  const pageComponent = usePageComponent();
  // const table = useTable();
  const filter = useFilter();


  const [openModalLogout, setOpenModalLogout] = React.useState(false);
  const handleOpenModalLogout = () => setOpenModalLogout(true);
  const handleCloseModalLogout = () => setOpenModalLogout(false);

  const handleClickMenuItem = (url: string) => {
    pageComponent.getPageComponent(url);
    navigation(url);
  }

  const handleOnLogoClick = () => {
    navigation(location.pathname + location.search);
  }
    
  React.useEffect(() => {
    if (drawerMainMenu.resourceRoutes.length != 0) return;
    drawerMainMenu.getDrawerMenuComponent();
  }, [])  

  return (
    <Drawer PaperProps={{sx: { boxShadow: 'none' }}} variant="permanent" open={open} sx={{position: 'relative', height: '100%', boxShadow: 'none'}}>
      <DrawerHeader>
        <Box sx={{padding: open ? '10px 0' : '5px 0', height: '80px', mt: 1, width: '100%'}}>
          {open 
            ? <IconButton 
                onClick={handleOnLogoClick}
                sx={logoSxProps} 
              >
                <LogoIcon style={{width: '100%', height: '100%'}} />
              </IconButton> 
            : <IconButton 
                onClick={handleOnLogoClick}
                sx={logoSxProps}
              >
                <LogoIconShort style={{width: '100%'}} />
              </IconButton>
          }
        </Box>
      </DrawerHeader>

      <Stack 
        direction='row' 
        justifyContent='left' 
        alignItems='center' 
        sx={{ 
          width: '100%', 
          px: 1.5,
          mb: 7,
          mt: 3
        }}
      >
        <Avatar sx={{backgroundColor: '#4D76AD', width: 60, height: 60, mr: 2}}>
          {user.name ? user.name[0] : ""}
        </Avatar>
        <Box>
          <Typography fontSize={12} color='rgba(0, 0, 0, 0.53)'>{user.preferred_username ?? ""}</Typography>
          <Typography fontSize={14} color='rgba(0, 0, 0, 0.53)'>{user.given_name ?? ""}</Typography>
          <Typography fontSize={14} color='rgba(0, 0, 0, 0.53)'>{user.family_name ?? ""}</Typography>
        </Box>
      </Stack>
      
      <Typography 
        fontSize={12} 
        textTransform='uppercase'
        color='rgba(0, 0, 0, 0.87)'
        sx={{
          px: 1.5,
          opacity: open ? 1 : 0,
        }}
      >
        Сервис
      </Typography>

      <Stack
        direction='column'
        justifyContent='space-between'
        alignItems='flex-start'
        sx={{
          height: '100%'
        }}
      >
        <List sx={{ width: '100%' }}>
          {drawerMainMenu.requestStatus.success && !drawerMainMenu.requestStatus.isLoading
          ? drawerMainMenu.resourceRoutes.map((resource, index) => (
              <DraweMenuItem 
                key={index} 
                resource={resource}
                isDrawerOpen={open} 
                currentServiceUrl={location.pathname}
                onClick={handleClickMenuItem}
              />
            ))
          : Array.from(new Array(3)).map((_, index) =>
              <DrawerMenuItemSkeleton key={index} openDrawer={open} />
            )
          }
        </List>

        <Box sx={{width: '100%'}}>
          <List sx={{width: '100%'}}>
            <OptionDrawerMenuItem 
              openDrawer={open}
              text='Свернуть'
              icon={open 
                ? <KeyboardDoubleArrowLeftIcon sx={{ width: 40, height: 40, color: '#000000' }} />
                : <KeyboardDoubleArrowRightIcon sx={{ width: 40, height: 40, color: '#000000' }} />
              }
              onClick={toogleExpandDrawer}
            />
            <OptionDrawerMenuItem 
              openDrawer={open}
              text='Выход'
              icon={<ExitToAppIcon />}
              onClick={handleOpenModalLogout}
            />
          </List>
          <Typography 
            fontSize={14} 
            color='#5A5A5A' 
            textAlign='left' 
            sx={{ 
              width: '100%', 
              pl: 2.5, 
              pb: 1.5 
            }}
          >
              v.0.1.1
          </Typography>
        </Box>
      </Stack>
      
      <ConditionModal
        title='Вы уверены, что хотите выйти?' 
        yesText='Выйти'
        noText='Назад'
        icon={<ExitToAppIcon />}
        open={openModalLogout} 
        onClose={handleCloseModalLogout} 
        onYes={auth.logout} 
      />
      <BackdropLoader open={auth.logoutRequestStatus.isLoading || false} />
    </Drawer>
  );
}

export default memo(MiniDrawer);