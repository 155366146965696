import React from "react";
import { useAppDispatch, useAppSelector } from "app/store/lib";
import { $api } from "shared/http/admin";
import { TableDataModel } from "./model/state";
import { tableActions } from "./model";
import { useApp } from "entities/app";
import { Column } from "entities/table-component/model/state";
import { AxiosRequestConfig } from "axios";

export const useTable = (tableId: string) => {
  const app = useApp();

  const dispatch = useAppDispatch();
  const tableInfo = useAppSelector(state => state.tableReducer);
  let updateTime: any;

  const initTable = async (id: string) => dispatch(tableActions.initTable(id));
  const setRowsPerPage = (id: string, rowsPerPage: number) => dispatch(tableActions.setRowsPerPage({id, rowsPerPage}));
  const setDataUrl = (id: string, dataUrl: string) => dispatch(tableActions.setDataUrl({id, dataUrl}));
  const setRows = (id: string, rows: any[]) => dispatch(tableActions.setRows({id, rows}));
  const setHeader = (id: string, header: Column[]) => dispatch(tableActions.setTableHeader({id, header}));
  const setHiddenHeader = (id: string, hiddenHeader: Column[]) => dispatch(tableActions.setHiddenHeader({id, hiddenHeader}));
  const setRequestConfig = (id: string, requestConfig: AxiosRequestConfig | null) => dispatch(tableActions.setRequestConfig({id, requestConfig}));
  const setIsNextPageTableDataEmpty = (id: string, isEmpty: boolean) => dispatch(tableActions.setIsNextPageTableDataEmpty({id, isEmpty}));
  
  const [isNextPageTableDataLoading, setIsNextPageTableDataLoading] = React.useState(false);

  const isColumnInHiddenList = (columnName: string) => tableInfo[tableId]?.hiddenHeader.filter(column => column.hide).map(column => column.title).includes(columnName);
  const getNorlmalHeader = () => tableInfo[tableId]?.header != undefined ? tableInfo[tableId].header.filter(column => !isColumnInHiddenList(column.title)).map(column => column.title) : [];
  const getOriginHeader = () => tableInfo[tableId]?.header != undefined ? tableInfo[tableId].header.filter(column => !isColumnInHiddenList(column.title)).map(column => column.name) : [];
  const getColumns = () => tableInfo[tableId]?.header != undefined ? tableInfo[tableId].header.filter(column => !isColumnInHiddenList(column.title)) : [];

  const isAnyColumnHide = () => tableInfo[tableId]?.hiddenHeader.filter(column => column.hide).length > 0;
  const isAnyColumnShow = () => tableInfo[tableId]?.hiddenHeader.filter(column => !column.hide).length !== 0;
  const isAllColumnsHide = () => tableInfo[tableId]?.hiddenHeader.filter(column => !column.hide).length === 0;

  const isEmpty = () => tableInfo[tableId]?.rows.length == 0;

  const saveColumnOrder = (currentColumnOrder: Column[]) => {
    localStorage.setItem(`${tableInfo[tableId].service}_${tableInfo[tableId].table}_columnOrder`, JSON.stringify(currentColumnOrder));
  }

  const changeAndSaveHiddenColumn = (column: Column) => {
    const newHiddenColumn = tableInfo[tableId].hiddenHeader.map(_column =>
      _column.title == column.title ? ({...column, hide: !column.hide}) : _column
    );
    localStorage.setItem(`${tableId}_hiddenColumn`, JSON.stringify(newHiddenColumn));
    setHiddenHeader(tableId, newHiddenColumn);
    
    const newColumn = tableInfo[tableId].header.map(_column =>
      _column.title == column.title ? ({...column, hide: !column.hide}) : _column
    );
    setHeader(tableId, [...newColumn]);
    localStorage.setItem(`${tableId}_columnOrder`, JSON.stringify(newColumn));
  }

  const restoreHiddenColumns = () => {
    const newHiddenColumn = tableInfo[tableId].hiddenHeader.map(column => ({...column, hide: false}));
    localStorage.setItem(`${tableId}_hiddenColumn`, JSON.stringify(newHiddenColumn));
    setHiddenHeader(tableId, newHiddenColumn);

    const newColumn = tableInfo[tableId].header.map(column => ({...column, hide: false}));
    setHeader(tableId, [...newColumn]);
    localStorage.setItem(`${tableId}_columnOrder`, JSON.stringify(newColumn));
  }

  const getData = async (
    dataUrl: string, 
    params: {
      limit: number, 
      offset: number,
      query?: any
    }
  ) => {

    dispatch(tableActions.tableRequestRestore(tableId));
    dispatch(tableActions.tableRequest(tableId));
    try {
      const response = await $api.post<TableDataModel>(dataUrl, { body: params });
      
      setRequestConfig(tableId, response.config);
      dispatch(tableActions.tableRequestSuccess({id: tableId, tableData: response.data}));
    } catch (e: any) {
      dispatch(tableActions.tableRequestError(e));
    }

    if(!tableInfo[tableId].countRows) {
      await checkNextPageTableData(dataUrl, {...params, offset: params.limit + params.offset});
    }

    // setTimeout(() => setIsLoadingTableGlobal(false), 500)
  }

  const checkNextPageTableData = async (
    dataUrl: string, 
    params: {
      limit: number, 
      offset: number,
      query?: any
    }
  ) => {
    try {
      const response = await $api.post<TableDataModel>(dataUrl, { body: params });
      setIsNextPageTableDataEmpty(tableId, response.data.data.length === 0);
    } catch (e: any) {
      // console.log(e);
    }
  }

  const getDataFromConfig = async (config: AxiosRequestConfig) => {
    if (!config.url) return;

    // dispatch(tableActions.tableRequestRestore(tableId));
    dispatch(tableActions.tableRequest(tableId));
    try {
      const response = await $api.request(config);
      dispatch(tableActions.tableRequestSuccess({id: tableId, tableData: response.data}));
    } catch (e: any) {
      dispatch(tableActions.tableRequestError(e));
    }
    // setTimeout(() => setIsLoadingTableGlobal(false), 500)
  }

  const moveColumn = (columnName: string, direction: 'left' | 'right') => {
    const currentColumnOrder = tableInfo[tableId].header.filter(column => !isColumnInHiddenList(column.name))
    const currentIndex = currentColumnOrder.findIndex(column => column.name == columnName);
    if (direction == 'left') {
      if (currentIndex != 0) {
        currentColumnOrder.splice(currentIndex - 1, 2, currentColumnOrder[currentIndex], currentColumnOrder[currentIndex - 1]);
        setHeader(tableId, [...currentColumnOrder]);
        localStorage.setItem(`${tableId}_columnOrder`, JSON.stringify(currentColumnOrder));
      }
    }
    if (direction == 'right') {
      if (currentIndex != currentColumnOrder.length - 1) {
        currentColumnOrder.splice(currentIndex, 2, currentColumnOrder[currentIndex + 1], currentColumnOrder[currentIndex]);
        setHeader(tableId, [...currentColumnOrder]);
        localStorage.setItem(`${tableId}_columnOrder`, JSON.stringify(currentColumnOrder));
      }
    }
  }


  const saveColumnOrderByIds = (columns: any[]) => {
    const columnArr = new Array(columns.length);
    columns.forEach(c => {
      columnArr[c.index] = tableInfo[tableId].hiddenHeader.find(h => h.name == c.name);
    });
    const currentColumnOrder = columnArr.filter(column => column && !isColumnInHiddenList(column.name))
    
    setHeader(tableId, [...currentColumnOrder]);
    localStorage.setItem(`${tableId}_columnOrder`, JSON.stringify(currentColumnOrder));
  }

  React.useEffect(() => {
    if (!tableInfo[tableId]) return;

    const savedColumnOrder = localStorage.getItem(`${tableId}_columnOrder`);
    const savedHiddenColumn = localStorage.getItem(`${tableId}_hiddenColumn`);
    if (savedColumnOrder) {
      setHeader(tableId, [...JSON.parse(savedColumnOrder)]);
    }
    
    if (savedHiddenColumn) {
      setHiddenHeader(tableId, [...JSON.parse(savedHiddenColumn)]);
    } else {
      setHiddenHeader(tableId, tableInfo[tableId].header);
    }
  }, [tableInfo[tableId]?.service, tableInfo[tableId]?.table])

  React.useEffect(() => {
  }, [tableInfo[tableId]?.header, tableInfo[tableId]?.hiddenHeader])

  React.useEffect(() => {
    if (app.autoUpdateEnable && !updateTime) {
      updateTime = setInterval(() => {
        if (tableInfo[tableId].requestStatus.isLoading) return;
        if (!tableInfo[tableId].requestConfig) return;
        // TODO
        // @ts-ignore
        getDataFromConfig(tableInfo[tableId].requestConfig);
      }, 1000 * 60);
    } else if (!app.autoUpdateEnable && updateTime) {
      clearInterval(updateTime);
      setRequestConfig(tableId, null);
    }

    return () => {
      clearInterval(updateTime);
    }
  }, [app.autoUpdateEnable, tableInfo[tableId]?.requestStatus.isLoading, tableInfo[tableId]?.requestConfig, updateTime])

  return { 
    ...tableInfo[tableId],
    isNextPageTableDataLoading,
    initTable,
    getNorlmalHeader,
    getOriginHeader,
    setIsNextPageTableDataEmpty,
    setIsNextPageTableDataLoading,
    
    getColumns,
    changeAndSaveHiddenColumn,
    restoreHiddenColumns,
    isAnyColumnHide,
    isAnyColumnShow,
    isAllColumnsHide,
    saveColumnOrder,
    saveColumnOrderByIds,
    setRowsPerPage,
    setRows,

    setHiddenHeader,
    setDataUrl,
    setHeader,
    moveColumn,
    isEmpty,
    getData,
    checkNextPageTableData
  }
}