import Grid from "@mui/material/Grid"
import { buildComponents } from "shared/utils/build"

export const GridComponent: React.FC<{
  children?: React.ReactNode,
  componentName: string,
  direction?: any,
  xs?: number,
  justifyContent?: string
  alignItems?: string,
  marginBottom?: number,
  innerAlign?: string
  innerVerticalAlign?: string

  height?: number | string;
  width?: number | string;
  margin?: number[];
  padding?: number[];

}> = ({ 
  children, 
  componentName,
  xs, 
  alignItems, 
  justifyContent, 
  innerAlign,
  innerVerticalAlign,
  direction = 'row',
  marginBottom = 0,
  height = 'unset', 
  width = '100%',
  margin = [0, 0, 0, 0],
  padding = [0, 0, 0, 0]
  
}) => {

  const translateProps: any = {
    "items-center": "center",
    "items-start": "stretch",
    "start": "flex-start",
    "text-left": "left",
    "left": "flex-start",
    "col": "column",
    "row": "row",
  }

  const getProps = (defaultProp: string) =>  translateProps[defaultProp] || defaultProp;

  const isContainer = xs == undefined;
  const gridType = isContainer ? 'container' : 'item'

  const getMinWidth = () => {
    if (!children) return '';
    if (!isContainer) {
      // @ts-ignore
      const { componentName: name } = children[0]?.props ?? {};
      return name == 'card' ? '' : 'min-content'
    } else {
      return '';
    }
  }

  return (
    // @ts-ignore
    <Grid
      componentName={componentName + ' ' + gridType}
      container={isContainer ? true : undefined}
      item={!isContainer ? true : undefined}
      direction={getProps(direction)}
      // @ts-ignore
      justifyContent={!isContainer ? translateProps[innerVerticalAlign] || undefined : getProps(justifyContent)}
      //justifyContent={!isContainer ? getProps(innerAlign) : getProps(justifyContent)}
      // @ts-ignore
      alignItems={!isContainer ? getProps(innerAlign) : getProps(alignItems)}
      xs={children ? xs : 'auto'}
      sx={{
        minWidth: getMinWidth(),
        display: !isContainer ? 'flex' : '',
        marginBottom: marginBottom,
        height: height,
        flexGrow: children ? 0 : '1 !important',
        width: children ? width : 'unset !important',
        margin: margin.join('px ') + 'px',
        padding: padding.join('px ') + 'px',
      }}
    >
      {children}
    </Grid>
  )
}

export const buildGridComponent = (component: any) => (
  <GridComponent 
    componentName={component.component} 
    xs={component['size']}
    direction={component['type']} 
    justifyContent={component['horizontal_align']}
    alignItems={component['vertical_align']}
    marginBottom={component['margin_bottom']}
    innerAlign={component['align']}
    innerVerticalAlign={component['inner_vertical_align']}
    height={component['height']} 
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
  >
    {component['content'] != undefined ? component['content'].map((c: any) => buildComponents(c)) : null}
  </GridComponent>
)