export interface ISignal {
  action: string;
  args?: any;
}

interface IAppState {
  currentUrlTable: string;
  rowsPerPage: number;
  autoUpdateEnable: boolean;
  signals: {
    [to: string]: ISignal | null;
  };
}


export const initialAppState: IAppState = {
  currentUrlTable: '',
  rowsPerPage: 10,
  autoUpdateEnable: false,
  signals: {}
}
