import { Box, IconButton, Pagination, PaginationItem, TablePagination, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';


interface DataTablePaginationProps {
  isLoading: boolean;
  page: number;
  count: number;
  rowsPerPage?: number;
  isTableEmpty?: boolean;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void
}


const DataTablePagination: React.FC<DataTablePaginationProps> = ({
  isLoading,
  page,
  count,
  isTableEmpty = false,
  rowsPerPage = 0,
  handleChangePage,
  handleChangeRowsPerPage
}) => {

  return (
    <TablePagination 
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      labelRowsPerPage={<Typography component='p' color='rgba(0, 0, 0, 0.60)' fontSize={12}>Строк на странице:</Typography>}
      labelDisplayedRows={({ from, to, count }) => count ? `${from}–${to} из ${count !== -1 ? count : `more than ${to}`}` : ''}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={(props: TablePaginationActionsProps) => <TablePaginationActions props={props} isTableEmpty={isTableEmpty} isLoading={isLoading} />}
    />
  );
}

const TablePaginationActions: React.FC<{
  props: TablePaginationActionsProps, 
  isTableEmpty: boolean, 
  isLoading: boolean
}> = ( {
  props, 
  isTableEmpty, 
  isLoading
}) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const isNextPageDisabled = () => {
    if (count) {
      if (page >= Math.ceil(count / rowsPerPage) - 1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (isTableEmpty || isLoading) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      {count && <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>}
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isNextPageDisabled()}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {count && <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>}
    </Box>
  );
}

export default DataTablePagination;