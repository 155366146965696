import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { normalizeValue } from "shared/utils/validation";
import moment from "moment";

interface IFilterChip {
  component?: string;
  name: string;
  select?: string;
  value: string;
  label?: string;
}

interface TableFilterChipsProps {
  chips: IFilterChip[];
  onDelete: (name: string) => void;
}

const TableFilterChips: React.FC<TableFilterChipsProps> = ({
  chips,
  onDelete
}) => {

  const filteredNoSelectChips = React.useMemo(() => {
      return chips.filter(chip => !(Object.keys(chip).includes('select') && !chip.select))
  }, [chips])

  const normalizeValue = (name: string, component: string, value: any) => {
    if (name.endsWith('_from') || name.endsWith('_to') || name.includes('date')) {
      let formatDatetime = '';
      if (component == 'date_picker') {
        formatDatetime = moment(value).format('DD.MM.YY');
      } else {
        formatDatetime = moment(value).format('DD.MM.YY HH:mm');
      }
      return formatDatetime;
    }
    return value;
  }

  return (
    <Stack 
      direction='row' 
      justifyContent='flex-start' 
      alignItems='center' 
      flexWrap='wrap' 
      sx={{ 
        width: '100%' 
      }}
    >
      {filteredNoSelectChips.map(chip => 
        <Box sx={{ m: 1 }}>
          <Chip 
            label={`${chip.label} - 
              ${normalizeValue(
                chip.name, 
                chip.component ?? "", 
                chip.select ?? chip.value
              )}`
            } 
            onDelete={() => onDelete(chip.name)}
          />
        </Box>
      )}
    </Stack>
  );
}

export default TableFilterChips;