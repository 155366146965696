import { Box } from "@mui/material";
import Button from "@mui/material/Button"

import './style.css';
import { MIcons } from "shared/ui/icon/checkbox/mui_icon";

export const ButtonComponent: React.FC<{
  children?: React.ReactNode | string,
  componentName: string,
  type?: string,
  size?: string,
  width?: number,
  height?: number,
  formId?: string,
  leftIcon?: any,
  onClick?: { event: string }
}> = ({ 
  componentName, 
  children, 
  onClick, 
  type='text', 
  size='medium', 
  width="max-content",
  height=40,
  leftIcon = <></> 
}) => {

  const getSafeTypeValue = (typeValue: string) => typeValue == "None" ? "outlined" : typeValue;

  const id = onClick?.event ? onClick?.event + 'Button' : undefined;

  const getTypeButton = () => {
    if (id == 'submitFormButton') {
      return 'submit';
    } else if (id == 'resetFormButton') {
      return 'reset';
    } else {
      return undefined
    }
  }

  return (
    <Box sx={{ p: 1, minHeight: 60, width, height }}>
      {/* @ts-ignore */}
      <Button
        id={id}
        componentName={componentName}
        className={["button", id].join(" ")}
        // fullWidth
        size={size}
        variant={getSafeTypeValue(type)}
        startIcon={leftIcon && leftIcon.type.render()}
        sx={{ fontSize: 12, whiteSpace: 'nowrap', lineHeight: 0, height: '100%'}}
        type={getTypeButton()}
      >
        {children}
      </Button>
    </Box>
  )
}

export const buildButtonComponent = (component: any) => (
  <ButtonComponent 
    componentName={component.component}
    size={component['size']}
    type={component['type']}
    width={component['width']}
    height={component['height']}
    formId={component['form_id']}
    onClick={component['onClick']}
    // @ts-ignore
    leftIcon={component['left_icon'] ? MIcons[component['left_icon']] : null}
  >
    {component['label']}
  </ButtonComponent>
)