import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import { ResourceRouteChild } from "entities/main-menu-component/model/state";

interface DrawerMenuItemPopoverProps {
  popover: HTMLButtonElement | null;
  resourceRouteChildren: ResourceRouteChild[];
  isChildSelected: (childRoute: ResourceRouteChild) => boolean;
  onChildClick: (url: string) => void;
  closePopover: () => void;
}

const DrawerMenuItemPopover: React.FC<DrawerMenuItemPopoverProps> = ({ 
  popover,
  resourceRouteChildren,
  onChildClick,
  closePopover,
  isChildSelected
}) => {
  const openPopover = Boolean(popover);
  const idPopover = openPopover ? 'drawer-menu-item-popover' : undefined;

  return (
    <Popover
      id={idPopover}
      open={openPopover}
      anchorEl={popover}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPaper-root': {
          left: '90px !important'
        }
      }}
    >
      <List dense={false} sx={{ py: 1, borderRadius: '8px' }}>
        {resourceRouteChildren.map(child =>
          <ListItem sx={{ p: 0 }}>
            <ListItemButton 
              onClick={() => onChildClick(child.path)} 
              selected={isChildSelected(child)}
            >
              <ListItemText primary={child.title} />
            </ListItemButton>
          </ListItem>  
        )}
      </List>
    </Popover>
  )
}

export default DrawerMenuItemPopover;