import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import React from "react";

import './style.css';
import { useFilter } from "entities/filter";
import { buildComponents } from "shared/utils/build";
import { MIcons } from "shared/ui/icon/checkbox/mui_icon";

import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { ISignal } from "entities/app/model/state";
import { useApp } from "entities/app";


export const PopoverComponent: React.FC<{
  children?: React.ReactNode | string,
  componentName: string,
  type?: string,
  size?: string,
  label?: string,
  padding?: number[],
  leftIcon?: any,
  textColor?: string,
}> = ({
  componentName, 
  children, 
  textColor = '#000000',
  label='', 
  type='text', 
  size='medium', 
  padding = [0, 0, 0, 0],
  leftIcon = <></> 
}) => {

  const app = useApp();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = (e?: any) => {
    if (e) e.stopPropagation();
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const idPopover = isOpen ? 'popover' : undefined;

  const handleSignal = (signal: ISignal) => {
    const { action, args } = signal;

    switch(action) {
      case 'close':
        handleClosePopover();
        break
    }
  }

  

  React.useEffect(() => {
    const signal = app.signals[componentName];
    if (signal) {
      handleSignal(signal);
      app.clearSignal(componentName);
    }
  }, [app.signals[componentName]])

  return (  
    <>
      {label 
        // @ts-ignore
        ? <Button
            componentName={componentName}
            fullWidth
            size={size}
            variant={type}
            className="popover-button"
            startIcon={leftIcon.type.render()}
            onClick={handleOpenPopover}
            sx={{ 
              fontSize: 12, 
              whiteSpace: 'nowrap', 
              lineHeight: 0, 
              color: textColor, 
              p: 2,
              '& .MuiSvgIcon-root': {
                color: textColor
              }
            }}
          >
            {label}
          </Button>
          // @ts-ignore
        : <IconButton
            componentName={componentName}
            className="popover-button"
            onClick={handleOpenPopover}
            sx={{
              '& .MuiSvgIcon-root': {
                color: textColor
              }
            }}
          >
            {leftIcon.type.render()}
          </IconButton>
      }
      <Popover
        className="popover"
        id={idPopover}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        onClick={(e: any) => e.stopPropagation()}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        sx={{
          '& .MuiPaper-root': {
            padding: padding.join('px ') + 'px',
          }
        }}
      >
        <Box
          component='div'
          // @ts-ignore
          componentName={componentName}
        >
          {children}
        </Box>
      </Popover>
    </>
  );
}

export const buildPopoverComponent = (component: any, options?: any) => (
  <PopoverComponent 
    componentName={component.component}
    padding={component['padding']}
    size={component['size']}
    type={component['type']}
    label={component['label']}
    textColor={component['text_color']}
    // @ts-ignore
    leftIcon={MIcons[component['left_icon']]}
  >
    {component['content'] != undefined ? component['content'].map((c: any) => buildComponents(c, options)) : (component['value'] ?? null)}
  </PopoverComponent>
)