export class ErrorRu {
  static enRu = {
    'Invalid user credentials': 'Неправильный логин или пароль'
  }

  static toRu (en: string): string {
    const defaultRu = "Что-то пошло не так";
    // @ts-ignore
    const ru = this.enRu[en] || defaultRu;
    return ru;
  }
}