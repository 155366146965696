import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { mainMenuComponentReducer } from "entities/main-menu-component";
import { tableComponentReducer } from "entities/table-component";
import { authReducer } from "entities/auth";
import { appReducer } from "entities/app";
import { tableReducer } from "entities/table";
import { filtersReducer } from "entities/filter";
import { pageComponentReducer } from "entities/page-component";
import { formFieldsReducer } from "entities/form-field";


const rootReducer = combineReducers({
  appReducer,
  authReducer,
  tableReducer,
  filtersReducer,
  formFieldsReducer,
  pageComponentReducer,
  tableComponentReducer,
  mainMenuComponentReducer,
})

export const setupStore = () =>{
  return configureStore({
    reducer: rootReducer,
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']