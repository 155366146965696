import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "shared/models/model";
import { Column } from "entities/table-component/model/state";
import { AxiosRequestConfig } from "axios";

import { 
  IHeaderTable, 
  initialTableSliceState, 
  initialTableState, 
  ITableState, 
  TableDataModel 
} from "./state";

const tableSlice = createSlice({
  name: 'table-data',
  initialState: initialTableSliceState,
  reducers: {
    initTable(state, action: PayloadAction<string>) {
      state[action.payload] = {...initialTableState, isInit: true};
    },
    tableRequest(state, action: PayloadAction<string>) {
      const table: ITableState = state[action.payload];
      if (!table) return;
      table.requestStatus.isLoading = true;
    },
    tableRequestSuccess(state, action: PayloadAction<{id: string, tableData: TableDataModel}>) {
      const table: ITableState = state[action.payload.id];
      if (!table) return;
      
      table.requestStatus.isLoading = false;
      table.requestStatus.error = '';
      table.rows = action.payload.tableData.data
      table.countRows = action.payload.tableData.total;
      table.footerData = action.payload.tableData.footer_data;
      table.requestStatus.success = true;
    },
    tableRequestError(state, action: PayloadAction<{id: string, error: any}>) {
      const table: ITableState = state[action.payload.id];
      if (!table) return;
      
      table.requestStatus.isLoading = false;
      table.requestStatus.success = false;
      table.requestStatus.error = action.payload.error;
    },
    tableRequestRestore(state, action: PayloadAction<string>) {
      const table: ITableState = state[action.payload];
      if (!table) return;
      table.requestStatus = initialTableState.requestStatus;
    },
    setTableHeader(state, action: PayloadAction<{id: string, header: Column[]}>) {
      const table: ITableState = state[action.payload.id];
      if (!table) return;
      
      table.header = action.payload.header;
    },
    setHiddenHeader(state, action: PayloadAction<{id: string, hiddenHeader: Column[]}>) {
      const table: ITableState = state[action.payload.id];
      if (!table) return;
      
      table.hiddenHeader = action.payload.hiddenHeader.map(hiddenColumn => ({
        ...hiddenColumn, 
        hide: hiddenColumn.hide != undefined ? hiddenColumn.hide : false
      }));
    },
    // setServiceAndTable(state, action: PayloadAction<{service: string, table: string}>) {
    //   state.service = action.payload.service;
    //   state.table = action.payload.table;
    // },
    // setIsLoadingTableGlobal(state, action: PayloadAction<boolean>) {
    //   state.isLoadingTableGlobal = action.payload;
    // },
    setDataUrl(state, action: PayloadAction<{id: string, dataUrl: string}>) {
      const table: ITableState = state[action.payload.id];
      if (!table) return;
      
      table.dataUrl = action.payload.dataUrl;
    },
    setRowsPerPage(state, action: PayloadAction<{id: string, rowsPerPage: number}>) {
      const table: ITableState = state[action.payload.id];
      if (!table) return;
      
      table.rowsPerPage = action.payload.rowsPerPage;
    },
    setRows(state, action: PayloadAction<{id: string, rows: any[]}>) {
      const table: ITableState = state[action.payload.id];
      if (!table) return;
      
      table.rows = action.payload.rows;
    },
    setRequestConfig(state, action: PayloadAction<{id: string, requestConfig: AxiosRequestConfig | null}>) {
      const table: ITableState = state[action.payload.id];
      if (!table) return;
      
      table.requestConfig = action.payload.requestConfig;
    },
    setIsNextPageTableDataEmpty(state, action: PayloadAction<{id: string, isEmpty: boolean}>) {
      const table: ITableState = state[action.payload.id];
      if (!table) return;
      
      table.isNextPageTableDataEmpty = action.payload.isEmpty;
    },
    // setIsNextPageTableDataLoading(state, action: PayloadAction<boolean>) {
    //   const table: ITableState = state[action.payload.id];
    //   state.isNextPageTableDataLoading = action.payload;
    // },
  }
});

export const tableActions = tableSlice.actions;
export const tableReducer = tableSlice.reducer;