import React from "react";
import { Box, IconButton, Stack, TextField, Tooltip } from "@mui/material"
import { useFilter } from "entities/filter";
import { useFormFields } from "entities/form-field";

import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { validate } from "./lib";

const textFieldSxProps = {
  '& .MuiFormLabel-root.MuiInputLabel-root[data-shrink="false"]': {
    top: '-6px',
  },
  '& .MuiInputBase-root': {
    height: 40,
  },
}

export const InputComponent: React.FC<{
  componentName: string,
  name?: string,
  placeholder?: string,
  type?: string,
  maxLength?: number,
  formId?: string,
  pattern?: string,
  tooltip?: string,
  defaultValue?: string,
  validation?: any[],
  required?: boolean,
  visible?: boolean,
}> = ({ 
  componentName, 
  name, 
  placeholder, 
  type,
  formId, 
  pattern, 
  maxLength,
  tooltip,
  defaultValue = "", 
  validation = [],
  required = false,
  visible = true,
}) => {
  const filter = useFilter();
  const formFields = useFormFields();

  const isPassword = type === 'password';
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [isError, setIsError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  // @ts-ignore
  const [value, setValue] = React.useState(filter.getFormFilterByName(formId, name)?.value ?? "");
  
  const handleChange = (e: any) => {
    let result = '';
    if (type === 'text') {
      result = e.target.value;
    } else if (type === 'number') {
      var regexPattern = /^\d+$/;
      // let regexPattern = new RegExp(pattern!)
      if (regexPattern.test(e.target.value)) {
        result = e.target.value;
      } else if (e.target.value === '') {
        result = '';
      }
    } else {
      result = e.target.value;
    }

    setValue(result);
  }

  const handleClearInputClick = () => setValue("");

  const handleOnBlur = (e: any) => {
    const [isValid, text] = validate(validation, e.target.value, { required });
    setIsError(!isValid);
    setHelperText(text);
  }


  React.useEffect(() => {
    if (formId && name) {
      setValue(
        filter.getFormFilterByName(formId, name)?.value || 
        formFields.getFormFieldByName(name, formId)?.value || 
        defaultValue
      );
    }
  }, [formId, name, filter.filters])

  React.useEffect(() => {
    let timeoutId = setTimeout(() => {
      const [isValid, _] = validate(validation, value.toString(), { required });
      formFields.updateFormField({
        form_id: formId ?? "",
        name: name || "",
        value,
        isValid
      })
    }, 100);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    }
  }, [value])

  return (
    <Box sx={{ p: 1, width: '100%', display: visible ? "block" : "none" }}>
      <TextField 
        // @ts-ignore
        componentName={componentName}
        // placeholder={placeholder}
        error={isError}
        helperText={isError ? helperText : null}
        label={placeholder}
        fullWidth
        type={isPassword ? showPassword ? 'text' : 'password' : 'text'}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleOnBlur}
        sx={textFieldSxProps} 
        data-form-id={formId}
        data-name={name}
        data-value={JSON.stringify(value)}
        data-label={placeholder}
        data-component={componentName}
        InputProps={{
          endAdornment: 
            <Stack direction='row'>
              {isPassword
                ? <IconButton onClick={toggleShowPassword} sx={{ px: 0.5 }}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                : null
              }
              {tooltip
                ? <Tooltip title={tooltip}>
                    <IconButton sx={{ px: 0.5 }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                : null
              }
              {value 
                ? <IconButton onClick={handleClearInputClick} sx={{ px: 0.5 }}>
                    <CloseIcon />
                  </IconButton>
                : null
              }
            </Stack>
        }}
      />
    </Box>
  )
}

export const buildInputComponent = (component: any) => (
  <InputComponent 
    componentName={component.component}
    name={component['name']}
    placeholder={component['placeholder']}
    defaultValue={component['value']}
    formId={component['form_id']}
    type={component['type']}
    pattern={component['pattern']}
    maxLength={component['max_length']}
    tooltip={component['tooltip']}
    required={component['required']}
    visible={component['visible']}
    validation={component['validation']}
  />
)