import { MainMenuResourceRoute } from "shared/http/admin-api/model";
import { LoginResponseModel } from "shared/http/keycloak-api/model";
import { RequestStatus } from "shared/models/model";

export interface IAuthState {
  loginInfo: LoginResponseModel;
  loginRequestStatus: RequestStatus;
  logoutRequestStatus: RequestStatus;
}

export const initialAuthState: IAuthState = {
  loginInfo: {} as LoginResponseModel,
  loginRequestStatus: {} as RequestStatus,
  logoutRequestStatus: {} as RequestStatus,
}