import { VariantType, useSnackbar } from 'notistack';

export const useWrapSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  
  const enqueueWrapSnackbar = (text: string, variant: VariantType) => {
    enqueueSnackbar(text, {  
      variant: variant, 
      anchorOrigin: { 
        vertical: 'top', 
        horizontal: 'right', 
      }, 
      style: { 
        maxWidth: 400 
      }  
    });
  }

  return { enqueueWrapSnackbar };
}