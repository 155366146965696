import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilter, initialFiltersState } from "./state";

const filtersSlice = createSlice({
  name: 'filter-table-data',
  initialState: initialFiltersState,
  reducers: {
    setFormFilters(state, action: PayloadAction<IFilter[]>) {
      state.filters = action.payload;
    },
  }
});

export const filtersActions = filtersSlice.actions;
export const filtersReducer = filtersSlice.reducer;