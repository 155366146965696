import KeyCloakApi from "shared/http/keycloak-api";
import { authActions } from "entities/auth";
import { ErrorRu } from "shared/utils/error_ru";
import { AppDispatch } from "app/store";


export function login(username: string, password: string) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(authActions.loginRequestRestore());
      dispatch(authActions.loginRequest());
      const response = await KeyCloakApi.login(username, password);
      dispatch(authActions.loginRequestSuccess(response.data));
    } catch (e: any) {
      try {
        dispatch(authActions.loginRequestError(ErrorRu.toRu(e.response.data.error_description)));
      } catch (e2: any) {
        dispatch(authActions.loginRequestError("Ошибка при авторизации"));
      }
    }
  }
}

export function logout() {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(authActions.logoutRequestRestore());
      dispatch(authActions.logoutRequest());
      const response = await KeyCloakApi.logout();
      dispatch(authActions.logoutRequestSuccess(response.data));
    } catch (e: any) {
      dispatch(authActions.logoutRequestError(e.response.data.error_description || "ERROR"));
    }
  }
}
