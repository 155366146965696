import React, {useState} from 'react';
import { Box, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import BackdropLoader from 'shared/ui/backdrop-loader';
import { useWrapSnackbar } from 'shared/hooks/wrap-snackbar';

import { ReactComponent as LogoIcon } from "shared/assets/logo.svg";
import { useAuth } from 'entities/auth';
import { UnderlineGreyTypography } from './ui';
import { PrimaryButton } from "shared/ui/button";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function AuthPage() {
  const { enqueueWrapSnackbar } = useWrapSnackbar();
  const { login, loginRequestStatus } = useAuth();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [loginName, setLoginName] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleLoginChange = (event: any) => setLoginName(event.currentTarget.value);
  const handlePasswordChange = (event: any) => setPassword(event.currentTarget.value);

  const handleKeypress = (event: any) => {  
    if (event.keyCode === 13) {      
      login(loginName, password);    
    }  
  };

  const handleLogin = () => {  
    login(loginName, password);   
  };

  return (
    <Stack 
      direction='column' 
      justifyContent='center' 
      alignItems='center'
      sx={{
        height: '100%',
        width: '100%'
      }}
    >
      <LogoIcon />
      <Box sx={{ mt: 8, padding: '40px 80px', backgroundColor: '#FAFAFA' }}>
        <Typography fontSize={24}>
          Пожалуйста, выполните вход
        </Typography>

        <Stack
          direction='column'
          spacing={2}
          sx={{
            width: '100%',
            mt: 6
          }}
        >
          <TextField 
            value={loginName}
            onChange={handleLoginChange}
            onKeyUp={handleKeypress}
            label="Логин" 
            variant="outlined" 
            fullWidth
          />
          <TextField 
            value={password}
            onChange={handlePasswordChange}
            onKeyUp={handleKeypress}
            label="Пароль" 
            variant="outlined" 
            type={showPassword ? 'text' : 'password'}
            fullWidth 
            InputProps={{
              endAdornment: <InputAdornment position="end">
              <IconButton
                aria-label={
                  showPassword ? 'hide the password' : 'display the password'
                }
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            }}
          />
        </Stack>

        <Box sx={{mt: 5}}>
          <PrimaryButton text='Войти' onClick={handleLogin} />
        </Box>

        <UnderlineGreyTypography
          onClick={() => {
            enqueueWrapSnackbar('Пожалуйста, обратитесь в службу поддержки support@bag24.ru', 'info');
          }}
        >
          Не помню логин или пароль
        </UnderlineGreyTypography>

      </Box>

      <BackdropLoader open={loginRequestStatus.isLoading || false} />
    </Stack>
  );
}

export default AuthPage;
