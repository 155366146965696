import React from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";


interface OptionDrawerMenuItemProps {
  openDrawer: boolean;
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export const OptionDrawerMenuItem: React.FC<OptionDrawerMenuItemProps> = ({ openDrawer, onClick, icon, text }) => (
  <ListItem disablePadding sx={{ display: 'block' }}>
    <ListItemButton
      onClick={onClick}
      sx={{
        minHeight: 48,
        justifyContent: openDrawer ? 'initial' : 'center',
        px: 2.5,
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: openDrawer ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} sx={{ opacity: openDrawer ? 1 : 0 }} />
    </ListItemButton>
  </ListItem>
)