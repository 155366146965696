import { useAppDispatch, useAppSelector } from 'app/store/lib';
import React from 'react';
import { formFieldsActions } from './model';
import { IFormField } from './model/state';


export const useFormFields = () => {
  const dispatch = useAppDispatch();
  const { fields } = useAppSelector(state => state.formFieldsReducer);

  const setFormFields = (formFeilds: IFormField[]) => {
    dispatch(formFieldsActions.setFormFields(formFeilds));
  }

  const updateFormField = (formFeild: IFormField) => {
    dispatch(formFieldsActions.updateFormFieldByName(formFeild));
  }

  const removeFormField = (formFeild: IFormField) => {
    dispatch(formFieldsActions.removeFormField(formFeild));
  }

  const removeFormFields = (formId: string, name: string | string[]) => {
    let updateFormFields: IFormField[] = [];
    if (Array.isArray(name)) {
      updateFormFields = fields.filter(f => !(f.form_id === formId && name.includes(f.name)));
    } else {
      updateFormFields = fields.filter(f => !(f.form_id === formId && f.name === name));
    }
    setFormFields(updateFormFields);
  }

  const resetFormFields = (formId: string) => {
    dispatch(formFieldsActions.setFormFields(
      fields.map(f => f.form_id === formId ? ({ ...f, value: null }) : f )
    ));
  }

  const getFormFieldByName = (name: string, formId: string) => {
    return fields.find(f => f.form_id === formId && f.name === name) || null;
  }

  const getFormFields = (formId: string) => {
    return fields.filter(f => f.form_id === formId);
  }

  return {
    fields,
    setFormFields,
    getFormFields,
    updateFormField,
    resetFormFields,
    removeFormField,
    removeFormFields,
    getFormFieldByName
  }
}