import React from 'react';
import { Stack, Tooltip, IconButton, Button } from "@mui/material"
import classNames from "classnames"
import { ReactComponent as ColumnSettingIcon } from 'shared/assets/column-settings.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

interface HiddenColumnButtonProps {
  isAnyColumnShow: boolean;
  openPopoverHidenColumn: boolean;
  handleHiddenColumnOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const HiddenColumnButton: React.FC<HiddenColumnButtonProps> = ({
  isAnyColumnShow,
  openPopoverHidenColumn,
  handleHiddenColumnOpen
}) => {
  
  return (
    <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
      >
        {isAnyColumnShow && 
        <Tooltip 
          title={`
            Для отображения таблицы необходимо 
            включить видимость хотя бы одной колонки. 
            Пожалуйста, перейдите в настройки и 
            выберите нужные колонки для отображения
          `}
        >
          <IconButton sx={{ mr: 1 }} onClick={() => {}}>
            <ErrorOutlineIcon sx={{ color: '#FFCC00' }}/>
          </IconButton>
        </Tooltip>}
        <Button 
          sx={{
            backgroundColor: openPopoverHidenColumn ? '#e4e4e4' : 'transparent',
            color: '#000000',
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.4px',
            border: 'none',
            '&:hover': {
              border: 'none',
              backgroundColor: openPopoverHidenColumn ? 'transparent' : '#e4e4e4',
              color: '#000000',
            }
          }}
          onClick={handleHiddenColumnOpen}
          variant="text"
          startIcon={<SettingsOutlinedIcon className={classNames('btn-filter-icon', {'btn-filter-icon-active': openPopoverHidenColumn})}/>}
        >
          Настройка таблицы 
        </Button>
      </Stack>
  )
}

export default HiddenColumnButton;