import { useApp } from "entities/app";
import { useFilter } from "entities/filter";
import { IFilter } from "entities/filter/model/state";
import { useTable } from "entities/table";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import './style.css';
import { buildComponents } from "shared/utils/build";
import { normalizeValue } from "shared/utils/validation";
import { useBindNavigator } from "shared/hooks/bind-navigator";
import { useLocationParams } from "shared/hooks/params";
import { useFormFields } from "entities/form-field";
import { tab } from "@testing-library/user-event/dist/tab";

export const FormComponent: React.FC<{
  children?: React.ReactNode,
  componentName: string,
  id: string,
  subscriber: string,
  enctype?: string,
  method?: string,
  height?: number | string,
  width?: number | string,
  margin?: number[],
  padding?: number[],
}> = ({ 
    children, 
    id, 
    method, 
    enctype,
    componentName,
    subscriber,
    height = 'unset',
    width = '800px',
    margin = [0, 0, 0, 0],
    padding = [24, 16, 24, 16]
  }) => {
  // const table = useTable();
  const app = useApp();
  const filter = useFilter();
  const formFields = useFormFields();

  const navigator = useNavigate();
  const { addToNavigateBar, clearNavigatorBar } = useBindNavigator<any>();
  const { params } = useLocationParams<any>(window.location.search);

  const getParentNodeComponentName = () : string | null => {
    const parentNode = document.getElementById(id!)?.parentNode;
    let componentName = null;
    if (parentNode) {
      // @ts-ignore
      componentName = parentNode.attributes.componentname.nodeValue;
    }

    return componentName;
  }

  const closeParentNode = () => {
    const parentNodeComponentName = getParentNodeComponentName();
    
    if (parentNodeComponentName) {
      app.addSignals(parentNodeComponentName, 'close');
    }
  }

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    closeParentNode();
    
    const paramFilters: IFilter[] = [];
    const formComponents = document.querySelectorAll(`[data-form-id="${id}"]`);
    formComponents.forEach(comp => {
      // @ts-ignore
      const { name, value, label, select, component, addictedby } = comp.dataset;
      let filterData = {
        value: normalizeValue(JSON.parse(value || "\"\"")), 
        name, 
        label,
        addictedby, 
        component, 
        formId: 
        id
      }

      if (select) {
        // @ts-ignore
        filterData = {...filterData, select}
      }
      paramFilters.push(filterData);
    });
    
    filter.updateFilters(paramFilters);
    const updatedFilters = filter.getUpdatedFilters(paramFilters);
    const updatedFormFilter = updatedFilters.filter(f => f.formId === id);

    clearNavigatorBar({
      page: params.page,
      rows: params.rows,
      tab: params.tab,
      ...filter.toParamsFromArray(updatedFormFilter)
    });

    app.addSignals(`subscriber_${subscriber}`, 'refresh', { params: filter.toParamsFromArray(updatedFormFilter) });
    // app.addSignals(`subscriber_main_page`, 'refresh', { params: filter.toParamsFromArray(updatedFormFilter) });
  }

  const resetForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    closeParentNode();
    filter.removeFormFilter(id)
    formFields.resetFormFields(id);

    clearNavigatorBar({
      page: params.page,
      rows: params.rows
    });

    app.addSignals(`subscriber_${subscriber}`, 'refresh', { params: filter.toParamsFromArray([]) });
  }

  React.useEffect(() => {
    const filterFields = filter.getFormFilters(id);
    formFields.setFormFields([
      ...filterFields.map(fl => ({ name: fl.name, form_id: fl.formId, value: fl.value }))
    ])

    return () => {
      formFields.resetFormFields(id);
    }
  }, [])

  return (
    <form 
      // @ts-ignore
      componentName={componentName} 
      id={id} 
      encType={enctype} 
      method={method} 
      onSubmit={submitForm}
      onReset={resetForm}
      className="popover-form"
      style={{
        margin: margin.join('px ') + 'px',
        padding: padding.join('px ') + 'px',
        height: height,
        width: width,
      }}
    >
      {children}
    </form>
  )
}

export const buildFormComponent = (component: any) => (
  <FormComponent 
    componentName={component.component}
    id={component['id']}
    enctype={component['enctype']}
    method={component['method']}
    subscriber={component['subscriber']}
    height={component['height']}
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
  >
    {component['content'] != undefined ? component['content'].map((c: any) => buildComponents(c)) : null}
  </FormComponent>
)