import React from 'react';
import { ListItem, ListItemButton, ListItemIcon, Skeleton } from "@mui/material";


interface DrawerMenuItemSkeletonProps {
  openDrawer: boolean
}

export const DrawerMenuItemSkeleton: React.FC<DrawerMenuItemSkeletonProps> = ({ openDrawer }) => (
  <ListItem disablePadding sx={{ display: 'block' }}>
    <ListItemButton
      sx={{
        minHeight: 48,
        maxHeight: 70,
        justifyContent: openDrawer ? 'initial' : 'center',
        px: 1.3
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          p: 1,
          mr: openDrawer ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        <Skeleton animation='wave' variant="rounded" sx={{height: '40px', width: '40px'}} />
      </ListItemIcon>
      
      <Skeleton animation='wave' variant="rectangular" sx={{height: '36px', width: '100%'}} />
    </ListItemButton>
    
  </ListItem>
)