import styled from "@emotion/styled";

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 5,
  width: 22,
  height: 22,
  backgroundColor: 'transparent',
  border: '2px solid #4D76AD',
  
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundPosition: 'center',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Group 229'%3E%3Cpath id='Vector' d='M16.6399 6.89661L11.8628 13.8769L8.56256 11.3794L6.89661 13.7329L12.5225 18.1035L18.9656 8.68971L16.6399 6.89661Z' fill='%234D76AD'/%3E%3C/g%3E%3C/svg%3E\")",
    content: '""',
  },
});