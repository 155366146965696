import { useApp } from "entities/app";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "shared/ui/layout/layout";

import { usePageComponent } from "entities/page-component";
import { useDrawerMainMenu } from "entities/main-menu-component";
import { buildComponents } from "shared/utils/build";
import { ISignal } from "entities/app/model/state";

const AdminPage:React.FC = () => {
  const id = "main_page";
  const navigation = useNavigate();
  const location = useLocation();
  const pageComponent = usePageComponent();
  const drawerMainMenu = useDrawerMainMenu();
  const app = useApp();

  const handleSignal = (signal: ISignal) => {
    const { action, args } = signal;

    switch(action) {
      case 'refresh':
        pageComponent.getPageComponent(location.pathname);
        break
    }
  }

  React.useEffect(() => {
    if (drawerMainMenu.requestStatus.success && drawerMainMenu.resourceRoutes.length > 0) {
      const firstMenuItemPath = drawerMainMenu.resourceRoutes[0].children[0].path;

      let nextPage = localStorage.getItem('nextPage'); 
      if (nextPage && nextPage !== '/' && !nextPage.startsWith('/?')) {
        navigation(nextPage);
        pageComponent.getPageComponent(new URL(window.location.origin + nextPage).pathname);
        localStorage.removeItem('nextPage');
      } else {
        pageComponent.getPageComponent(location.pathname === '/' ? firstMenuItemPath : location.pathname);
        navigation(location.pathname === '/' ? firstMenuItemPath : location.pathname + location.search);
      }
    }
  }, [drawerMainMenu.requestStatus.success])

  React.useEffect(() => {
    const signal = app.signals[`subscriber_${id}`];
    if (signal) {
      handleSignal(signal);
      app.clearSignal(`subscriber_${id}`);
    }
  }, [app.signals[`subscriber_${id}`]])

  if (pageComponent.isLoading) return null;

  return (
    <Layout>
      {buildComponents(pageComponent.component)}
    </Layout>
  );
}

export default AdminPage;