import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import React from "react";

interface CustomButtonProps {
  children: React.ReactNode,
  onClick: () => void
}

export const UnderlineGreyTypography: React.FC<CustomButtonProps> = ({ children, onClick }) => (
  <Typography 
    onClick={onClick}
    fontSize={16} 
    color='#888'
    textAlign='center'
    sx={{
      marginTop: '16px',
      textDecoration: 'underline',
      cursor: 'pointer',
      transition: '250ms',
      '&:hover': {
        opacity: '0.6'
      }
    }}
  >
    {children}
  </Typography>
)
