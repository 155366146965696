export interface IFormField {
  form_id: string;
  name: string;
  value?: any;
  isValid?: boolean;
}

interface IFormFieldsState {
  fields: IFormField[];
}

export const initialFormFieldsState: IFormFieldsState = {
  fields: [],
}