import { $api } from "../admin";
import { AxiosResponse } from "axios";
import { MainMenuResponseModel} from "./model";
import { PageComponentModel } from "entities/page-component/model/state";
import { ResourceRoutesModel } from "entities/main-menu-component/model/state";

export class AdminApi {

  static async getMainMenu(): Promise<AxiosResponse<ResourceRoutesModel>> {
    return $api.get<ResourceRoutesModel>(`/mainMenu`);
  }

  static async getPageComponent(url: string): Promise<AxiosResponse<PageComponentModel>> {
    return $api.post<PageComponentModel>(url + '/');
  }
  
  // static async getPageComponent(url: string): Promise<AxiosResponse<PageComponentModel>> {
  //   return $api.get<TableComponentModel>(`${url}?limit=${limit}&offset=${offset}`, { params });
  // }
}