import React from "react";
import { Stack } from "@mui/material";
import { 
	SimpleTreeView,
	TreeItem2,
	TreeItem2Props, 
	TreeItem2Label
} from "@mui/x-tree-view";
import { buildComponents } from "shared/utils/build";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface CustomLabelProps {
	children: React.ReactNode;
	labelPaddingRight: number;
	actions: React.ReactNode[];
}

const CustomLabel: React.FC<CustomLabelProps> = ({ 
	actions,
	labelPaddingRight, 
	...other 
}) => {
	return (
		<Stack direction='row' justifyContent='flex-start' alignItems='center' sx={{ width: '100%' }} >
			<TreeItem2Label {...other} sx={{ fontSize: 14 }} />
			<Stack direction='row' justifyContent='flex-end' sx={{ pr: labelPaddingRight }}>
				{actions}
			</Stack>
		</Stack> 
	);
}

interface CustemTreeItemProps extends TreeItem2Props {
	labelPaddingRight: number;
	labelPaddingLeft: number;
	actions: React.ReactNode[];
}

const CustomTreeItem = React.forwardRef(
	(props: CustemTreeItemProps, ref: React.Ref<HTMLLIElement>) => {
		const {
			actions,
			labelPaddingRight, 
			labelPaddingLeft,
			...other
		} = props;

		// const { interactions } = useTreeItem2Utils({
		// 	itemId: props.itemId,
		// 	children: props.children
		// });

		// const handleContentClick: UseTreeItem2ContentSlotOwnProps['onClick'] = (event) => {
		// 	event.defaultMuiPrevented = true;
		// 	interactions.handleSelection(event);
		// }

		// const handleIconContainerClick = (event: React.MouseEvent) => {
		// 	interactions.handleExpansion(event);
		// }

		return (
			<TreeItem2
				{...other}
				ref={ref}
				slots={{ label: CustomLabel }}
				
				slotProps={{
					content: { style: { paddingLeft: labelPaddingLeft, paddingTop: 12, paddingBottom: 12, } },
					// iconContainer: { onClick: handleIconContainerClick },
					label: { 
						labelPaddingRight, 
						actions
					} as any 
				}}
			/>
		)
	}
)

interface TreeViewItemProps {
	id: string;
	parent_id?: string;
	label: string;
	items?: TreeViewItemProps[];
}

interface TreeViewComponentProps {
  componentName: string;
	items: TreeViewItemProps[];
	actions: any[];
}

export const TreeViewComponent: React.FC<TreeViewComponentProps> = ({
	componentName,
	items,
	actions = []
}) => {

	const buildTree = (
		items: TreeViewItemProps[] = [], 
		labelPaddingRight: number = 2,
		labelPaddingLeft: number = 2,
	) => {
		return (
			<>
				{items.map(item => 
					<CustomTreeItem 
						itemId={item.id} 
						label={item.label} 
						labelPaddingRight={labelPaddingRight + 4}
						labelPaddingLeft={labelPaddingLeft + 4}
						actions={actions.map(action => 
							buildComponents(action, { 
								data: item, 
								extraBody: { 
									parent_id: item.id
								} 
							})
						)}
					>
						{item.items ? buildTree(item.items, labelPaddingRight + 4, labelPaddingLeft + 20) : undefined}
					</CustomTreeItem>
				)}
			</>
		)
	}

	return (
		<SimpleTreeView
			slots={{
				expandIcon: AddIcon,
				collapseIcon: RemoveIcon,
			}}
			sx={{ 
				width: '100%', 
				height: '100%' 
			}}
		>
			{buildTree(items)}
		</SimpleTreeView>
	)
}

export const buildTreeViewComponent = (component: any) => (
  <TreeViewComponent 
    componentName={component.component}
    items={component['items']}
		actions={component['actions']}
  />
)