import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialTableState, TableComponentModel } from "./state";
import { RequestStatus } from "shared/models/model";

const tableComponentSlice = createSlice({
  name: 'table-component',
  initialState: initialTableState,
  reducers: {
    setTableComponent(state, action: PayloadAction<TableComponentModel>) {
      state.tableComponent = action.payload;
    }
  }
});

export const tableComponentActions = tableComponentSlice.actions;
export const tableComponentReducer = tableComponentSlice.reducer;