import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as LogoIcon } from "../../shared/assets/logo.svg";
import { ReactComponent as ServiceErrorIcon } from "../../shared/assets/service-error-icon.svg";
import { CustomButton, PrimaryButton } from "shared/ui/button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { searchParamsToObject, searchParamsToObjectWithRepeated } from "shared/utils/params";

import './style.css';

const ServiceErrorPage = () => {

  const location = useLocation();
  const navigation = useNavigate();

  // @ts-ignore
  const handleClickUpdate = () => window.location.assign(searchParamsToObjectWithRepeated(location.search).requestPage ?? '/');

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={7}
      sx={{
        py: 5,
        width: '100%',
        height: '100%',
      }}
    >
      <Box className='logo'>
        <LogoIcon style={{width: '100%', height: '100%'}} />
      </Box>

      <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          className='container'
        >
          <ServiceErrorIcon />
      </Stack>
      <Typography
        className='text'
        fontWeight={400}
        textAlign='center'
      >
        Ведутся технические работы.<br/>
        Приносим свои извинения за неудобства!
      </Typography>
      <Box className="button">
        <CustomButton  onClick={handleClickUpdate}>
          Обновить страницу
        </CustomButton>
      </Box>
    </Stack>
  )
}

export default ServiceErrorPage