import { AppDispatch } from "app/store";
import { pageComponentActions } from "./model";
import { AdminApi } from "shared/http/admin-api";

export function getPageComponent(url: string) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(pageComponentActions.pageComponentRequestRestore());
      dispatch(pageComponentActions.pageComponentRequest());
      const response = await AdminApi.getPageComponent(url);
      dispatch(pageComponentActions.pageComponentRequestSuccess(response.data));
    } catch (e: any) {
      // dispatch(mainMenuComponentActions.tableComponentRequestError(e.response?.data.error_description || "ERROR"));
      dispatch(pageComponentActions.pageComponentRequestError("ERROR"));
    }
  }
}