import axios from "axios";
import { config } from "process";
import { LoginResponseModel } from "./keycloak-api/model";
// import { setUnauthorized } from "shared/config/error";

const $apiCloack = axios.create({
  baseURL: process.env.REACT_APP_KEY_CLOAK_API_URL,
  withCredentials: false
});

$apiCloack.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    config.headers = config.headers ?? {};
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const refreshTokenRequest = async () => {
  const params = new URLSearchParams();
    params.append('refresh_token', localStorage.getItem('refresh_token') ?? '');
    params.append('grant_type', 'refresh_token');
    params.append('client_id', process.env.REACT_APP_KEY_CLOAK_CLIENT_ID || "");
    params.append('client_secret', process.env.REACT_APP_KEY_CLOAK_CLIENT_SECRET || "");
  
    const result = await axios.post<LoginResponseModel>(
    `${process.env.REACT_APP_KEY_CLOAK_API_URL}/realms/${process.env.REACT_APP_KEY_CLOAK_REALM_NAME}/protocol/openid-connect/token`, 
    params, 
    {withCredentials: false}
  );

  localStorage.setItem('access_token', result.data.access_token);
  localStorage.setItem('refresh_token', result.data.refresh_token);
}

$apiCloack.interceptors.response.use(
  (res) => res,
  async (e) => {
    console.log(e);
    const originalRequest = e.config;
    if (e.response.status === 401 && e.config && !e.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        await refreshTokenRequest();
        return $apiCloack.request(originalRequest);
      } catch (e) {
        //localStorage.clear(); // FIXME after expired sessioin nextPage is removed
        console.log('NON AUTHORIZED K');
      }
      
    }
    
    return Promise.reject(e);
  }
);


export default $apiCloack;