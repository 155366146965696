import { Box } from '@mui/material';
import React from 'react';
import { buildComponents } from 'shared/utils/build';

interface CardComponentProps {
  children?: React.ReactNode,
  componentName: string,
  height?: number | string;
  width?: number | string;
  margin?: number[];
  padding?: number[];
}

export const CardComponent: React.FC<CardComponentProps> = ({ 
  children, 
  componentName,
  height = '100%',
  width = 'unset',
  margin = [0, 0, 0, 0],
  padding = [0, 0, 0, 0]
}) => {
  return (
    <Box 
      // @ts-ignore
      component={componentName}
      sx={{ 
        border: '1px solid rgba(0, 0, 0, 0.12)', 
        borderRadius: '12px',
        margin: margin.join('px ') + 'px',
        padding: padding.join('px ') + 'px',
        height: height,
        width: width,
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {children}
    </Box>
  );
}

export const buildCardComponent = (component: any) => (
  <CardComponent 
    componentName={component.component}
    height={component['height']}
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
  >
    {component['content'] != undefined ? component['content'].map((c: any) => buildComponents(c)) : null}
  </CardComponent>
)