import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/store/lib";
import { useWrapSnackbar } from "shared/hooks/wrap-snackbar";
import * as KeyCloakService from './api';
import { useNavigate, useParams } from "react-router-dom";
import { authActions } from "./model";

export const useAuth = () => {
  const { nextPage }  = useParams<{nextPage: string}>()
  const navigation = useNavigate();
  const { enqueueWrapSnackbar } = useWrapSnackbar();
  const dispatch = useAppDispatch();
  const {
    loginInfo,
    loginRequestStatus,
    logoutRequestStatus,
  } = useAppSelector(state => state.authReducer);

  const login = (loginName: string, password: string) => {
    dispatch(KeyCloakService.login(loginName, password))
  }

  const logout = () => {
    dispatch(KeyCloakService.logout());
  }

  // LOGIN SUCCESS
  useEffect(() => {
    if (loginRequestStatus.success) {
      enqueueWrapSnackbar("Авторизация прошла успешно", 'success');
      localStorage.setItem('access_token', loginInfo.access_token);
      localStorage.setItem('refresh_token', loginInfo.refresh_token);
      navigation('/');
      dispatch(authActions.loginRequestRestore());
    }
    
  }, [loginRequestStatus.success])

  // LOGIN ERROR
  useEffect(() => {
    if (loginRequestStatus.error) {
      enqueueWrapSnackbar(loginRequestStatus.error, 'error');
    }
  }, [loginRequestStatus.error])

  // WHEN TOKEN HAS EXPIRED
  useEffect(() => {
    const logoutReason = localStorage.getItem('logout_reason');
    if (logoutReason) {
      enqueueWrapSnackbar(logoutReason, 'warning');
      localStorage.removeItem('logout_reason');
    }
  }, [])

  // LOGOUT SUCCESS
  useEffect(() => {
    if (logoutRequestStatus.success) {
      caches.delete('image');
      localStorage.clear();
      window.location.reload();
    }
  }, [logoutRequestStatus])

  return { 
    login, 
    logout,
    loginInfo, 
    loginRequestStatus, 
    logoutRequestStatus 
  }
}