import React from "react";
import { Box, FormControlLabel, IconButton, Stack, SxProps, TextField, Typography } from "@mui/material";
import { useFormFields } from "entities/form-field";
import { encode } from "base64-arraybuffer";
import { useWrapSnackbar } from "shared/hooks/wrap-snackbar";
import {filesize} from "filesize";

import {useDropzone} from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import './style.css';

// const labelSxProps: SxProps = {
//   m: 0,
//   backgroundColor: '#5B90E1',
//   color: '#ffffff',
//   padding: '5px 10px',
//   borderRadius: '4px',
//   transition: '250ms',
//   '&:hover': {
//     opacity: 0.6
//   }
// }

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#0000001F',
  borderStyle: 'dashed',
  color: '#000000DE',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#5B90E1'
};

const INVALID_TYPE_FILE = "Неправильный формат файла! Поддерживаемые форматы: JPEG, JPG, PNG";
const INVALID_FILESIZE = "Размер файла превышает заданный лимит";

export const FileComponent: React.FC<{
  componentName: string,
  name?: string,
  label?: string,
  formId?: string,
  width?: number,
  height?: number,
}> = ({ 
  componentName, 
  name, 
  formId,
  width,
  height,
  label = "PNG или JPG до 5 Мб", 
}) => {
  const {
    acceptedFiles, 
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: {'image/*': []}});
  
  const style = React.useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const { enqueueWrapSnackbar } = useWrapSnackbar();
  // const filter = useFilter();
  const formFields = useFormFields();
  const acceptedFile = ["jpeg", "jpg", "png", "heic"];

  // @ts-ignore
  const [value, setValue] = React.useState<File | null>(null);
  const handleChange = (files: any) => {
    if (files && files.length > 0) {
      const file = files[0];

      if (file.size > 5242880) {
        enqueueWrapSnackbar(INVALID_FILESIZE, "error");
        return;
      }

      try {
        const ext = file.name.split('.').at(-1);
        if (ext) {
          if (!acceptedFile.includes(ext.toLowerCase())) {
            enqueueWrapSnackbar(INVALID_TYPE_FILE, "error");
            return;
          }
        } else {
          enqueueWrapSnackbar(INVALID_TYPE_FILE, "error");
          return;
        }
      } catch {
        enqueueWrapSnackbar(INVALID_TYPE_FILE, "error");
      }


      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (e.target && e.target.result) {
          const bytes = e.target.result as ArrayBuffer;

          formFields.updateFormField({
            form_id: formId ?? "",
            name: name ?? "",
            value: encode(bytes)
          });
          
        }
      }

      setValue(file);
      fileReader.readAsArrayBuffer(file);
      
    }
  }

  const handleOnFileDelete = () => {
    setValue(null);
    formFields.updateFormField({
      form_id: formId ?? "",
      name: name ?? "",
      value: ""
    });
  }

  React.useEffect(() => {
    handleChange(acceptedFiles);
  }, [acceptedFiles])

  return (
    <Stack sx={{ p: 1 }}>
      <div 
        // @ts-ignore
        componentName={componentName}
        data-form-id={formId}
        data-name={name}
        data-label={label}
        // @ts-ignore
        {...getRootProps({style: style})} 
      >
        <input {...getInputProps()} />
        <UploadFileIcon sx={{ color: "#5B90E1", mb: 2 }} />
        <Box sx={{ mb: 2 }}>
          <Typography
            component='span'
            color="#5B90E1"
            sx={{
              cursor: 'pointer',
              textDecoration: "underline",
              mr: 1,
            }}
          >
            Кликните
          </Typography>
          <Typography component='span'>
            или перетащите файл
          </Typography>
        </Box>
        {
          height && width 
          ? <Typography color='#00000099'>
              Размер изображения - {width}х{height}
            </Typography>
          : null
        }
        <Typography color='#00000099'>
          {label}
        </Typography>
      </div>
      
      {value
        ? <Stack 
            direction='row'
            justifyContent='flex-start'
            alignItems='center'  
            sx={{ width: '100%', mt: 2 }}
          >
            <Box className="preview">
              <img src={URL.createObjectURL(value)} />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography>{value.name}</Typography>
              <Typography 
                color='#00000099'
                fontSize={14}
              >
                {filesize(value.size, {standard: "jedec"})}
              </Typography>
            </Box>
            <Stack 
              direction='row'
              justifyContent='flex-start'
              alignItems='center' 
            >
              <IconButton onClick={handleOnFileDelete}>
                <DeleteIcon sx={{ color: "#0000008F" }} />
              </IconButton>
              <CheckCircleIcon
                fontSize='large' 
                sx={{ color: "#2e7d32" }}
              />
            </Stack>
          </Stack>
        : null
      }
      
      {/* <FormControlLabel 
        label={value ? `${label}: ${value.name}` : label}
        sx={labelSxProps}
        control={
          <TextField
            // @ts-ignore
            componentName={componentName}
            type="file"
            // value={value}
            onChange={handleChange}
            data-form-id={formId}
            data-name={name}
            // data-value={value}
            data-label={label}
            data-component={componentName}
            sx={{ display: "none" }}
            inputProps={{
              accept: "image/jpeg, image/jpg, image/png, image/heic"
            }}
          />
      } />  */}
    </Stack>
  )
}

export const buildFileComponent = (component: any) => (
  <FileComponent 
    componentName={component.component}
    name={component['name']}
    formId={component['form_id']}
    label={component['label']}
    width={component['width']}
    height={component['height']}
  />
)