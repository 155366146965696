import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import AuthPage from "pages/auth-page";
import NotFoundPage404 from "pages/404-page";
import ServiceErrorPage from "pages/service-error-page";
import AdminPage from "pages/admin-page";
import DetailPage from "pages/detail-page";


const PrivateRoute = () => {
  const auth = localStorage.getItem('access_token');
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

const UnAuthRoute = () => {
  const auth = localStorage.getItem('access_token');
  return !auth ? <Outlet /> : <Navigate to='/'/>;
};



export const Routing = () => {
  return (
    <Routes>
      <Route path="/login" element={<UnAuthRoute />}>
        <Route path="/login" element={<AuthPage />} />
        <Route path="/login?next=:nextPage" element={<AuthPage />} />
      </Route>

      <Route path="/:serviceSource/:tableSource" element={<PrivateRoute />}>
        <Route path="/:serviceSource/:tableSource" element={<AdminPage />} />
      </Route>

      <Route path="/:serviceSource/:tableSource/:id" element={<PrivateRoute />} >
        <Route path="/:serviceSource/:tableSource/:id" element={<DetailPage />} />
      </Route>

      <Route path="/:serviceSource/:tableSource/:admin/:id" element={<PrivateRoute />} >
        <Route path="/:serviceSource/:tableSource/:admin/:id" element={<DetailPage />} />
      </Route>

      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<AdminPage />} />
      </Route>

      <Route path="/service-error" element={<ServiceErrorPage />} >
        <Route path="/service-error?requestPage=:requestPage" element={<ServiceErrorPage />} />
      </Route>

      <Route path="/not-found" element={<PrivateRoute />}>
        <Route path="/not-found" element={<NotFoundPage404 />} />
      </Route>

      <Route path="*" element={<PrivateRoute />}>
        <Route path="*" element={<NotFoundPage404 />} />
      </Route>

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
