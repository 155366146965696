import Typography from "@mui/material/Typography";
import { Tooltip } from "antd";

const MAX_TEXT_LENGTH = 50;

export const TextComponent: React.FC<{
  value?: string | any,
  componentName: string,
  size?: string,
  align?: "right" | "left" | "center" | "inherit" | "justify" | undefined,
  margin?: number[],
  padding?: number[],
}> = ({ 
  componentName, 
  value = "", 
  size ='14px', 
  align = "center",
  margin = [0, 0, 0, 0],
  padding = [0, 0, 0, 0]
}) => {

  const isObject = typeof(value) == 'object';

  // @ts-ignore
  const {value: text, text_color, bg_color} = value;

  return (
    
    <>
      {!isObject 
        ? <Tooltip title={value.length > MAX_TEXT_LENGTH ? value : ""}>
            {/* @ts-ignore */}
            <Typography
              className="component"
              componentName={componentName}
              align={align}
              fontSize={size}
              sx={{
                margin: margin.join('px ') + 'px',
                padding: padding.join('px ') + 'px',
              }}
            >
            {value.length > MAX_TEXT_LENGTH ? value.slice(0, MAX_TEXT_LENGTH) + '...' : value}
          </Typography>
        </Tooltip>
        // @ts-ignore
        : <Typography 
            width='min-content'
            align={align}
            fontSize={size ? size : '14px'}
            color={text_color ?? 'black'}
            whiteSpace='nowrap'
            sx={{ 
              margin: margin.join('px ') + 'px',
              padding: padding.join('px ') + 'px',
              width: '100%',
              borderRadius: '6px',
              backgroundColor: bg_color ?? 'transparent',
            }}
          >
            {text}
          </Typography>
      }
    </>
    
  )
}

export const buildTextComponent = (component: any, value?: any, align?: string) => (
  <TextComponent 
    componentName={component.component} 
    value={value ?? component['value']}
    size={component['size']}
    align={(typeof(value) === 'object' ? value['align'] : undefined) || align || component['align']}
    margin={component['margin']}
    padding={component['padding']}
  />
)