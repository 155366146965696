import { useLocation, useNavigate } from "react-router-dom";
import { objectToParamSting, searchParamsToObjectWithRepeated } from "shared/utils/params";

export function useBindNavigator<T>(pathname: string = window.location.pathname) {
  const navigator = useNavigate();
  const location = useLocation();

  const addToNavigateBar = (params: T) => {
    navigator(`${pathname}${objectToParamSting({
      ...searchParamsToObjectWithRepeated(location.search),
      ...params
    })}`)
  }

  const clearNavigatorBar = (excludeParams: T) => {
    navigator(`${pathname}${objectToParamSting({
      ...excludeParams
    })}`)
  }

  return { addToNavigateBar, clearNavigatorBar };
}