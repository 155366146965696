import $apiCloack from "../keycloak";
import { AxiosResponse } from "axios";
import { 
  LoginResponseModel
} from "./model";

export default class KeyCloakApi {
  static async login(username: string, password: string): Promise<AxiosResponse<LoginResponseModel>> {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('scope', 'openid');
    params.append('grant_type', 'password');
    params.append('client_id', process.env.REACT_APP_KEY_CLOAK_CLIENT_ID || "");
    params.append('client_secret', process.env.REACT_APP_KEY_CLOAK_CLIENT_SECRET || "");

    return $apiCloack.post<LoginResponseModel>(`/realms/${process.env.REACT_APP_KEY_CLOAK_REALM_NAME}/protocol/openid-connect/token`, params);
  }

  static async logout(): Promise<AxiosResponse> {
    const params = new URLSearchParams();
    params.append('refresh_token', localStorage.getItem('refresh_token') ?? '');
    params.append('client_id', process.env.REACT_APP_KEY_CLOAK_CLIENT_ID || "");
    params.append('client_secret', process.env.REACT_APP_KEY_CLOAK_CLIENT_SECRET || "");
    return $apiCloack.post(`/realms/${process.env.REACT_APP_KEY_CLOAK_REALM_NAME}/protocol/openid-connect/logout`, params);
  }

}