import { RequestStatus } from "shared/models/model";

export interface ResourceRoutesModel {
  resourceRoutes: ResourceRoute[];
}

export interface ResourceRoute {
  title:    string;
  icon:     string;
  path?:    string;
  children: ResourceRouteChild[];
}

export interface ResourceRouteChild {
  title: string;
  path:  string;
}


interface IMainMenuComponentState {
  resourceRoutes: ResourceRoute[];
  requestStatus: RequestStatus;
}

export const initialMainMenuState: IMainMenuComponentState = {
  resourceRoutes: [],
  requestStatus: {} as RequestStatus
}