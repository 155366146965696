import { useApp } from "entities/app";
import React from "react";
import {  useNavigate, useParams } from "react-router-dom"
import { $api } from "shared/http/admin";
import Layout from "shared/ui/layout/layout"
import { buildComponents } from "shared/utils/build";

import { AxiosError } from "axios";
import { useWrapSnackbar } from "shared/hooks/wrap-snackbar";

import { ISignal } from "entities/app/model/state";


const DetailPage = () => {
  const id = "detail_page";
  const app = useApp();
  const { enqueueWrapSnackbar } = useWrapSnackbar();
  const navigation = useNavigate();
  
  const [isLoading, setIsLoading] = React.useState(false);
  const [components, setComponents] = React.useState(null);
  const [error, setError] = React.useState<AxiosError>();

  
  const getDetailPage = async () => {
    setIsLoading(true);
    try {
      const response = await $api.post(`${window.location.pathname}`);
      setComponents(response.data);
    } catch (error: any) {
      setError(error)
    }
    setIsLoading(false);
  }

  const handleSignal = (signal: ISignal) => {
    const { action, args } = signal;

    switch(action) {
      case 'refresh':
        getDetailPage();
        break
    }
  }

  React.useEffect(() => {
    const signal = app.signals[`subscriber_${id}`];
    if (signal) {
      handleSignal(signal);
      app.clearSignal(`subscriber_${id}`);
    }
  }, [app.signals[`subscriber_${id}`]])

  React.useEffect(() => {
    getDetailPage();
  }, [])

  React.useEffect(() => {
    if (error) {
      // @ts-ignore
      if (error.toJSON().status == 422) {
        window.history.back();
        enqueueWrapSnackbar("Такого заказа не существует", "warning");
        // @ts-ignore
      } else if (error.toJSON().status == 404) {
        navigation('/not-found');
      }
    }
  }, [error]) 

  return (
    <Layout>
      {buildComponents(components)}
    </Layout>
  )
}

export default DetailPage;