import React from 'react';
import { ListItem, ListItemButton, Tooltip, ListItemIcon, ListItemText, Collapse, List, Popover, Typography, Stack, Box, SxProps } from "@mui/material";
import { MainMenuResourceRoute } from 'shared/http/admin-api/model';
import { ResourceRoute, ResourceRouteChild } from 'entities/main-menu-component/model/state';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import DrawerMenuItemPopover from './drawer-menu-item-popover';


interface DrawerMenuItemProps {
  isDrawerOpen: boolean,
  currentServiceUrl: string,
  resource: ResourceRoute,
  onClick: (url: string) => void,
}

export const DraweMenuItem: React.FC<DrawerMenuItemProps> = ({ 
  resource, 
  isDrawerOpen, 
  currentServiceUrl,
  onClick,
}) => {
  const navigator = useNavigate();
  const [openSubItems, setOpenSubItems] = React.useState(false);


  ///////////////// DRAWER MENU POPOVER ////////////////////////////////////////////
  const [drawerMenuItemPopover, setDrawerMenuItemPopover] = React.useState<HTMLButtonElement | null>(null);
  const handleDrawerMenuItemPopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDrawerMenuItemPopover(event.currentTarget);
  };

  const handleDrawerMenuItemPopoverClose = () => {
    setDrawerMenuItemPopover(null);
  };
  //////////////////////////////////////////////////////////////////////////////////
  

  const handleDrawerMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (resource.children.length === 0 && resource.path) {
      // navigator(path);
      onClick(resource.path);
      return;
    }

    if (isDrawerOpen) {
      setOpenSubItems(!openSubItems);
    } else {
      handleDrawerMenuItemPopoverOpen(event);
    }
  };

  const isDrawerMenuItemSelected = () => {
    return !openSubItems &&
      resource.children.map(child => child.path).includes(currentServiceUrl);
  }

  const isDrawerMenuItemChildSelected = (childPath: string) => childPath === currentServiceUrl; 

  React.useEffect(() => {
    if (!isDrawerOpen) {
      setOpenSubItems(false);
    }
  }, [isDrawerOpen])

  return (
    <>
      <ListItem disablePadding sx={drawerMenuItemSxProps}>
        <ListItemButton
          onClick={(event: any) => handleDrawerMenuItemClick(event)}
          selected={isDrawerMenuItemSelected()}
          sx={drawerMenuItemButttonSxProps(isDrawerOpen)}
        >
          
          <Tooltip title={!isDrawerOpen ? resource.title : ''}>
            <ListItemIcon sx={drawerMenuItemIconSxProps(isDrawerOpen)}>
              {/* <NetworkImage url={item.iconUrl} /> */}
              <img src={resource.icon} style={{ width: '100%', height: '100%' }}/>
            </ListItemIcon>
          </Tooltip>
          
          <ListItemText 
            primary={resource.title} 
            primaryTypographyProps={{
              fontSize: 16,
              whiteSpace: 'break-spaces'
            }}
            sx={{ 
              opacity: isDrawerOpen ? '1' : '0',
            }} 
          />
          {isDrawerOpen && <>{ openSubItems ? <ExpandLess /> : <ExpandMore /> }</>}
          
        </ListItemButton>
      </ListItem>

      <DrawerMenuItemPopover
        popover={drawerMenuItemPopover}
        resourceRouteChildren={resource.children}
        closePopover={handleDrawerMenuItemPopoverClose}
        isChildSelected={(childRoute) => childRoute.path === currentServiceUrl}
        onChildClick={onClick} 
      />

      {resource.children.length != 0 &&
        <Collapse in={openSubItems} timeout="auto" unmountOnExit>
          <Stack 
            direction='row' 
            justifyContent='flex-start' 
            alignItems='center' 
            sx={drawerMenuItemChildrenContainerSxProps}
          >
            <Box sx={drawerMenuItemChildrenDividerSxProps} /> 
            <List component="div" disablePadding sx={{ width: '100%', px: 1.5 }}>
              {resource.children.map(resourceChild =>
                <ListItemButton 
                  onClick={() => onClick(resourceChild.path)} 
                  selected={isDrawerMenuItemChildSelected(resourceChild.path)}
                  sx={drawerMenuItemChildrenButtonSxProps} 
                >
                  <ListItemText 
                    primary={resourceChild.title} 
                    sx={drawerMenuItemChildrenTextSxProps} 
                  />
                </ListItemButton>  
              )}
            </List>
          </Stack>
        </Collapse>
      }
    </>
  )
}

const drawerMenuItemSxProps: SxProps = {
  display: 'block', 
  px: 1.5, 
  py: 0.5 
};

const drawerMenuItemIconSxProps = (isDrawerOpen: boolean): SxProps => ({
  minWidth: 0,
  width: 24,
  height: 24,
  mr: isDrawerOpen ? 2 : 'auto',
  ml: '6px',
  justifyContent: 'center',
});

const drawerMenuItemButttonSxProps = (isDrawerOpen: boolean): SxProps => ({
  minHeight: 48,
  maxHeight: 70,
  justifyContent: isDrawerOpen ? 'initial' : 'center',
  px: 1.3,
  borderRadius: '8px',
  overflow: 'hidden'
});

const drawerMenuItemChildrenContainerSxProps: SxProps = {
  width: '100%', 
  height: '100%', 
  pl: 5 
}

const drawerMenuItemChildrenDividerSxProps: SxProps = {
  height: '100%', 
  width: '1px', 
  backgroundColor: 'rgba(0, 0, 0, 0.12)' 
}

const drawerMenuItemChildrenButtonSxProps = {
  pl: 4, 
  borderRadius: '8px' 
}

const drawerMenuItemChildrenTextSxProps = {
  '& .MuiTypography-root': { 
    fontSize: 14 
  }
}