import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialPageState, PageComponentModel } from "./state";
import { RequestStatus } from "shared/models/model";

const pageComponentSlice = createSlice({
  name: 'page-component',
  initialState: initialPageState,
  reducers: {
    pageComponentRequest(state) {
      state.requestStatus.isLoading = true;
    },
    pageComponentRequestSuccess(state, action: PayloadAction<PageComponentModel>) {
      state.requestStatus.isLoading = false;
      state.requestStatus.error = '';
      state.pageComponent = action.payload;
      state.requestStatus.success = true;
    },
    pageComponentRequestError(state, action: PayloadAction<any>) {
      state.requestStatus.isLoading = false;
      state.requestStatus.success = false;
      state.requestStatus.error = action.payload;
    },
    pageComponentRequestRestore(state) {
      state.pageComponent = {} as PageComponentModel;
      state.requestStatus = {} as RequestStatus;
    }
  }
});

export const pageComponentActions = pageComponentSlice.actions;
export const pageComponentReducer = pageComponentSlice.reducer;