import { AxiosResponse } from "axios";

interface Action {
	name: string;
	[args: string]: any
}

enum ACTION_AFTER_SUCCESS_REQUEST {
  SAVE = 'SAVE',
  RELOAD = 'RELOAD'
}

const handleOnSuccessAction = (actions: Action[], response: AxiosResponse) => {
	actions.forEach(action => {
		switch (action.name) {
			case ACTION_AFTER_SUCCESS_REQUEST.SAVE:
				ActionHandle.save(action, response);
				break;
			case ACTION_AFTER_SUCCESS_REQUEST.RELOAD:
				ActionHandle.reload();
				break;
			default:
				break;
		}
	})
}

export default handleOnSuccessAction;

class ActionHandle {
	static save(action: Action, response: AxiosResponse) {
		const value = response.data[action.key_data];
		localStorage.setItem(action.key_storage, value);
	}

	static reload() {
		window.location.reload();
	}
}