import { Divider } from "@mui/material"

export const HorizontalLineComponent: React.FC<{
  componentName: string,
}> = ({ componentName }) => {
  return (
    // @ts-ignore
    <Divider componentName={componentName} sx={{ width: '100%' }} />
  )
}

export const buildHorizontalLineComponent = (component: any) => (
  <HorizontalLineComponent componentName={component.component}/>
)