import React from 'react'

import Box from '@mui/material/Box';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./style.css";
import plugImage from 'shared/assets/plug.png';
import { Stack } from '@mui/material';


interface CarouselComponentProps {
  componentName: string;
  images: any[];
  height?: number | string;
  width?: number | string;
  margin?: number[];
  padding?: number[];
}

export const CarouselComponent:React.FC<CarouselComponentProps> = ({ 
  images = [], 
  height = '100%', 
  width = 'unset',
  margin = [0, 0, 0, 0],
  padding = [0, 0, 0, 0]
}) => {
  return (
    <Box 
      sx={{ 
        // mb: 2,
        // ml: 2,
        height: height,
        width: width,
        margin: margin.join('px ') + 'px',
        padding: padding.join('px ') + 'px',
        // maxHeight: 350, 
        // maxWidth: 1000,
      }}
    >
      {images.length == 0
        ? <img src={plugImage} style={{ 
            width: '100%', 
            height: '100%', 
            objectFit: 'contain',
            padding: '50px',
            border: '1px solid rgba(0, 0, 0, 0.12)', 
            borderRadius: '12px', 
          }}
        />
        : <ImageGallery
            showBullets={false}
            showPlayButton={false}
            autoPlay={false}
            // thumbnailPosition='right'
            showThumbnails={false}
            items={images.map((image, idx, arr) => ({
              renderItem: (item) => {
                const td = JSON.parse(item.description ?? "{}");
                return (
                  <>
                    <img
                      alt="sample video cover"
                      className="image-gallery-image"
                      src={item.original}
                      style={{ objectFit: image['url'] ? 'cover' : 'contain' }}
                    />
                    <Box
                      className="image-gallery-counter"
                    >
                      {idx + 1}/{arr.length}
                    </Box>

                    {item.description && (
                      <Stack 
                        className="image-gallery-description"
                        direction='column'
                        justifyContent='center'
                        alignItems='flex-start'
                        spacing={1}
                      >
                        <p className="title">
                          {td['title']}
                        </p>
                        {td['description'] 
                          ? <p className="description">
                              {td['description']}
                            </p>
                          : null
                        }
                      </Stack>
                    )}
                  </>
              )},
              original: image['url'] ?? plugImage,
              thumbnail: image['url'] ?? plugImage,
              originalHeight: undefined,
              originalWidth: undefined,
              thumbnailHeight: 40,
              thumbnailWidth: 40,
              description: JSON.stringify({title: image['title'] ?? '', description: image['description'] ?? ''}),
              thumbnailClass: 'thumbnail-gallery'
            }))} 
          />
      }
    </Box>
  );
}

export const buildCarouselComponent = (component: any) => (
  <CarouselComponent 
    componentName={component.component}
    images={component['images']}
    height={component['height']} 
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
  />
)