export interface IFilter {
  formId: string;
  name: string;
  label: string;
  value: any;
  component?: string;
  select?: string;
  addictedby?: string;
}

interface IFilterState {
  filters: IFilter[];
}

export const initialFiltersState: IFilterState = {
  filters: [],
}