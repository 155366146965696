import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderGetTagProps, AutocompleteProps } from '@mui/material/Autocomplete';

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from "@mui/icons-material/Info";
import { useFilter } from 'entities/filter';
import { Chip, IconButton, Stack, Tooltip } from '@mui/material';
import { ISelectValue } from 'shared/component/select';

const REPLACE_STR = 'Международный аэропорт ';

const sxPropsAutocomplete = (isMultiple: boolean) => ({
  '& .MuiInputBase-root': {
    height: isMultiple ? 'unset' : 40,
    minHeight: 40,
  },
  '& .MuiInputBase-root input': {
    marginTop: isMultiple ? '-12px' : 0
  },
  '& .MuiFormLabel-root.MuiInputLabel-root[data-shrink="false"]': {
    top: '-6px',
  },
})

interface ListSelectorProps {
  label?: string;
  name?: string;
  isMultiple: boolean;
  formId?: string;
  componentName?: string;
  addictedBy?: string;
  tooltip?: string,
  addictedValue?: string | null;
  items: ISelectValue[];
  paramName: string;
  value: string[] | string | null;
  // translateItems?: any;
  disabled?: boolean;
  setValue(value: ISelectValue[] | ISelectValue | null): void;
}

const ListSelectorAutocomplete: React.FC<ListSelectorProps> = ({
  label, 
  name,
  formId,
  isMultiple,
  componentName,
  addictedBy,
  addictedValue,
  items, 
  paramName, 
  value, 
  setValue, 
  tooltip,
  disabled=false, 
  // translateItems={}
}) => {
  
  const getSelectValue = (value: string[] | string | null) => {
    if (!items || items.length === 0) {
      return isMultiple ? [] : null;
    }

    if (Array.isArray(value)) {
      return value.map(v => items.find(item => item.value === v) as ISelectValue);
    } else {
      return items.find(item => item.value === value) || null;
    }
  }

  const getInputValue = (value: ISelectValue[] | ISelectValue | null) => {
    if (Array.isArray(value)) {
      return value.map(v => v.label || "").join(',');
    } else {
      return value ? value.label : "";
    }
  }
  
  const selectValue = getSelectValue(value);
  const [selectedItem, setItem] = React.useState<ISelectValue[] | ISelectValue | null>(selectValue);
  const [inputText, setInputText] = React.useState<string>(getInputValue(selectValue));
  const [multipleInputText, setMultipleInputText] = React.useState<string>('');
  const [disabledIfOneOrNull, setDisabledIfOneNull] = React.useState(disabled);


  const handleChange = (newValue: ISelectValue[] | ISelectValue | null) => {
    setItem(newValue);
    setValue(newValue);
  };

  const handleInputChange = (newInputValue: string) => {
    if (paramName == 'locationId') {
      setInputText(newInputValue.replace(REPLACE_STR, ''));
    } else {
      setInputText(newInputValue);
    }
  }

  const handleMultipleInputChange = (newValues: string[]) => {
    if (!isMultiple) return;
    setMultipleInputText(newValues.join(', '))
  }

  const handleClearIconClick = (event: any) => {
    event.preventDefault();
    setValue(isMultiple ? [] : null);
  }

  const renderTags = (values: ISelectValue[], getTagProps: AutocompleteRenderGetTagProps) => {
    if (values.length === 1 && !values[0].label) return;
          
    handleMultipleInputChange(values.map(v => v.label));
    return (
      values.map((option: ISelectValue, index: number) => (
        <Chip variant="outlined"  size='small' label={option.label} {...getTagProps({ index })} />
      ))
    )
  }

  React.useEffect(() => {
    if (value) {
      const selectValue = getSelectValue(value);
      setItem(selectValue);
      setInputText(getInputValue(selectValue).replaceAll(REPLACE_STR, ''));
    }
  }, [value])


  React.useEffect(() => {
    if (addictedBy) {
      if (!addictedValue) {
        handleChange(isMultiple ? [] : null);
        setDisabledIfOneNull(true);
      } else {
        if (items.length === 1) {
          setValue(isMultiple ? [items[0]] : items[0]);
          setDisabledIfOneNull(true);
        } else if (items.length === 0) {
          handleChange(isMultiple ? [] : null);
          setDisabledIfOneNull(true);
        } else {
          setDisabledIfOneNull(false);
        }
      }
    } 
  }, [addictedBy, addictedValue])

  return(
    <Autocomplete
      data-form-id={formId}
      data-name={name}
      data-value={JSON.stringify(value)}
      data-label={label}
      data-component={componentName}
      data-select={isMultiple ? multipleInputText : inputText}
      data-addictedby={addictedBy}
      noOptionsText='Не найдено'
      multiple={isMultiple}
      disabled={disabledIfOneOrNull}
      value={selectedItem}
      
      inputValue={(inputText ?? "").replace(REPLACE_STR, '')}
      
      options={items}
      
      onChange={(event: any, newValue: ISelectValue[] | ISelectValue | null) => handleChange(newValue)}
      
      onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
      clearIcon={<CloseIcon sx={{ width: 20, height: 20 }} onClick={handleClearIconClick} />}
      // open={false}
      
      renderInput={(params) => 
        <TextField 
          {...params} 
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Stack direction='row'>
              {tooltip
                ? <Tooltip title={tooltip}>
                    <IconButton sx={{ p:0 }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                : null
              }
              {params.InputProps.endAdornment}
            </Stack>
          }}
        />
      }

      renderTags={isMultiple ? renderTags : undefined}
      sx={sxPropsAutocomplete(isMultiple)}
    />
    
  );
}

// const ListSelector: React.FC<ListSelectorProps> = ({label, items, paramName, value, setValue, translateItems={}}) => {
  
//   const [selectedItem, setItem] = React.useState(value);

//   const handleChange = (event: SelectChangeEvent) => {
//     setItem(event.target.value as string);
//     setValue(event.target.value == '-' ? '' : event.target.value);
//   };

//   return(
//     <FormControl sx={sxProps}>
      
//       <InputLabel >{label}</InputLabel>
//       <Select
//         value={selectedItem}
//         label={label}
//         onChange={handleChange}
//       >
//         {items.map((item, index) =>
//           <MenuItem value={item}>{translateItems[item] ? translateItems[item] : item}</MenuItem>
//         )}
//       </Select>
//     </FormControl>
    
//   );
// }

export { ListSelectorAutocomplete }