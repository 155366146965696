import React from 'react';
import { Stack, TableHead, TableRow } from "@mui/material"
import { StyledTableCell } from "./table-cell"

import { Column } from 'entities/table-component/model/state';
import { MIcons } from '../icon/checkbox/mui_icon';


interface TableHeaderProps {
  columns: Column[];
  defaultHeaderBackground: boolean;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ columns, defaultHeaderBackground }) => {

  if (!columns) {
    return <></>
  }

  const renderIcon = (icon: any, color: string = '#000000') => icon 
    ? <Stack alignItems='center' sx={{ '& .MuiSvgIcon-root': { fill: color } }}>
      {/* @ts-ignore */}
        {MIcons[icon].type.render()}
      </Stack> 
    : null;

  return (
    <TableHead>
      <TableRow>
        
        {
          [
            <>
              {columns && columns.length != 0 && 
                <StyledTableCell 
                  key={'numbercol'}
                  align='left'
                  style={{ minWidth: 'max-content', width: 30, height: 50, backgroundColor: defaultHeaderBackground ?  ''  : 'white' }}
                >
                  №
                </StyledTableCell>
              }
            </>
          ].concat(columns.map(column => (
            <StyledTableCell 
              key={column.name}
              // @ts-ignore
              align={column.align || 'left'}
              style={{ 
                // width: column.size,
                whiteSpace: 'nowrap',
                cursor: 'default',
                height: 50,
                width: column.size || "unset",
                backgroundColor: defaultHeaderBackground ?  ''  : 'white'
              }}
            >
              <Stack direction='row' justifyContent={column.align || 'left'} alignItems='center' spacing={0.5}>
                {renderIcon(column.left_icon, column.left_icon_color)}
                <p>
                  {column.title}
                </p>
                {renderIcon(column.right_icon, column.right_icon_color)}
              </Stack>
            </StyledTableCell>
          )))
        }
      </TableRow>
    </TableHead>
  )
}