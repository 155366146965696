import { Popover, Box, Stack, Typography, Checkbox, Button } from "@mui/material";
import { Column } from "entities/table-component/model/state";
import { IHeaderTable } from "entities/table/model/state";
import React from "react";
import { BpIcon, BpCheckedIcon } from "shared/ui/icon/checkbox";
import RGL, { WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';

const EXCLUDE_COLUMNS = [
  'id',
  'act_number'
]

const ReactGridLayout = WidthProvider(RGL);

const buttonSxProps = {
  backgroundColor: '#DDECFF',
  color: '#DF5959',
  textTransform: 'uppercase',
  fontSize: 16,
  border: 'none',
  transition: '250ms',
  '&:hover': {
    border: 'none',
    opacity: 0.7,
    backgroundColor: '#DDECFF',
    color: '#DF5959',
  }
}


interface HiddenColumnPopoverProps {
  hiddenHeader: Column[];
  isAnyColumnHide: boolean;
  idHiddeColumn: string | undefined;
  openPopoverHidenColumn: boolean;
  openHiddenColumn: Element | null;
  handleHiddenColumnClose: () => void;
  restoreHiddenColumns: () => void;
  changeAndSaveHiddenColumn (column: Column): void;
  changeAndSaveColumnsOrder (columns: any[]): void;
}

const HiddenColumnPopover: React.FC<HiddenColumnPopoverProps> = ({
  hiddenHeader,
  isAnyColumnHide,
  idHiddeColumn,
  openHiddenColumn,
  openPopoverHidenColumn,
  handleHiddenColumnClose,
  restoreHiddenColumns,
  changeAndSaveHiddenColumn,
  changeAndSaveColumnsOrder
}) => {
  return (
    <Popover
      id={idHiddeColumn}
      open={openPopoverHidenColumn}
      anchorEl={openHiddenColumn}
      onClose={handleHiddenColumnClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          width: 400,
          p: 2
        }}
      >
        <ReactGridLayout
          layout={hiddenHeader.map((column, cIndex) => ({
            x: 0,
            y: cIndex,
            w: 15,
            h: 1,
            isResizable: false,
            i: column.name
          }))}
          onLayoutChange={(layout: any[]) => changeAndSaveColumnsOrder(layout.map(l => ({name: l.i, index: l.y})))}
          rowHeight={30}
          className="layout"
          cols={12}
        >
          {hiddenHeader.map((column, i) => 
            <Stack key={column.name} direction='row' justifyContent='space-between' alignItems='center' sx={{ opacity: EXCLUDE_COLUMNS.includes(column.name) ? 0.6 : 1 }}>
              <Typography fontSize={16} color='#5A5A5A'>
                {column.title ? column.title : `[${column.name}]`}
              </Typography>
              <Checkbox
                disabled={EXCLUDE_COLUMNS.includes(column.name)}
                checked={!column.hide}
                onMouseDown={(event: any) => event.stopPropagation()}
                onChange={() => changeAndSaveHiddenColumn(column)}
                icon={<BpIcon />}
                checkedIcon={<BpCheckedIcon />}
              />
            </Stack>  
          )}
        </ReactGridLayout>
        
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          sx={{ 
            width: '100%',
            mt: 3 
          }}
        >
          <Button 
            sx={buttonSxProps}
            disabled={!isAnyColumnHide}
            onClick={() => restoreHiddenColumns()}
            variant="outlined"
          >
            СБРОСИТЬ
          </Button>
        </Stack>
      </Box>
    </Popover>
  )
}

export default HiddenColumnPopover;