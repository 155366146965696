import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { useFilter } from "entities/filter";
import { useFormFields } from "entities/form-field";
import React from "react";

const textFieldSxProps = {
  // minWidth: 245,
  // p: 1,
  // mr: 2, 
  // mb: 2,
  // height: 40,
  // '& .MuiFormLabel-root.MuiInputLabel-root label': {
  //   top: '0',
  // },
  '& .MuiFormLabel-root.MuiInputLabel-root[data-shrink="false"]': {
    top: '-6px',
  },
  // '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
  //   top: '5px',
  //   length: '5px'
  // },
  '& .MuiInputBase-root': {
    height: 40,
  },
  // '& .MuiInputBase-input': {
  //   padding: '10px 12px',
  // },
  // '& legend': {
  //   fontSize: '0.65rem !important'
  // }
}

export const CheckboxComponent: React.FC<{
  componentName: string,
  name?: string,
  label?: string,
  formId?: string
}> = ({ componentName, name, label, formId }) => {
  const filter = useFilter();
  const formFields = useFormFields();

  // @ts-ignore
  const [value, setValue] = React.useState(filter.getFormFilterByName(formId, name)?.value as boolean ?? false);
  const handleChange = (e: any) => {
    const { checked } = e.target;
    setValue(checked);

    formFields.updateFormField({
      form_id: formId || "",
      name: name || "",
      value: checked
    })
  }

  React.useEffect(() => {
    if (formId && name) {
      setValue((filter.getFormFilterByName(formId, name)?.value || formFields.getFormFieldByName(name, formId)?.value || false) as boolean);
    }
  }, [formId, name, filter.filters])

  return (
    <Box sx={{ p: 1 }}>
      <FormControlLabel 
        label={label}
        control={
          <Checkbox
            // @ts-ignore
            componentName={componentName}
            checked={value}
            onChange={handleChange}
            data-form-id={formId}
            data-name={name}
            data-value={JSON.stringify(value)}
            data-label={label}
            data-component={componentName}
          />
      } /> 
    </Box>
  )
}

export const buildCheckboxComponent = (component: any) => (
  <CheckboxComponent 
    componentName={component.component}
    name={component['name']}
    formId={component['form_id']}
    label={component['label']}
  />
)