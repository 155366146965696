import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/store/lib";
import { appActions } from "./model";


export const useApp = () => {
  const dispatch = useAppDispatch();
  const {
    autoUpdateEnable,
    currentUrlTable,
    rowsPerPage,
    signals
  } = useAppSelector(state => state.appReducer);

  const addSignals = (to: string, action: string, args?: any) => dispatch(appActions.addSignal({ to, action, args }));
  const clearSignal = (to: string) => dispatch(appActions.clearSignal({ to })); 

  const setCurrentUrlTable = (urlTable: string) => {
    dispatch(appActions.setCurrentUrlTable(urlTable));
  }

  const sethAutoUpdate = (checked: boolean) => {
    dispatch(appActions.setAutoUpdate(checked));
  };

  const setRowsPerPage = (rowsPerPage: number) => {
    dispatch(appActions.setRowsPerPage(rowsPerPage));
  };

  const getSavedAppState = () => JSON.parse(localStorage.getItem('app') ?? "{}") as { 
    url: string, 
    rowsPerPage?: number, 
    autoUpdateEnable?: boolean,
  };

  const isAppStateSave = () => Object.keys(getSavedAppState()).length > 0 ? true : false;

  const setAppState = (
    appState: {
      url?: string
      rowsPerPage?: number,
      autoUpdateEnable?: boolean,
    },
    save: boolean = true
  ) => {

    if (appState.url) {
      if (save) localStorage.setItem('app', JSON.stringify({...getSavedAppState(), url: appState.url }))
      setCurrentUrlTable(appState.url);
    }

    if (appState.autoUpdateEnable != undefined) {
      if (save) localStorage.setItem('app', JSON.stringify({...getSavedAppState(), autoUpdateEnable: appState.autoUpdateEnable }))
      sethAutoUpdate(appState.autoUpdateEnable);
    }

    if (appState.rowsPerPage != undefined) {
      if (save) localStorage.setItem('app', JSON.stringify({...getSavedAppState(), rowsPerPage: appState.rowsPerPage }))
      setRowsPerPage(appState.rowsPerPage);
    }
  }

  React.useEffect(() => {
    const appState = getSavedAppState();
    setAppState({...appState}, false);
  }, [])
  
  return { 
    isAppStateSave,
    autoUpdateEnable,
    currentUrlTable,
    rowsPerPage,
    setAppState,
    getSavedAppState,
    
    addSignals,
    clearSignal,
    signals
  };
}