import { useAppDispatch, useAppSelector } from 'app/store/lib';
import moment from 'moment';
import React from 'react';
import { filtersActions } from './model';
import { IFilter } from './model/state';
import { getObjectFromParams } from 'shared/utils/url';
import { searchParamsToObjectWithRepeated } from 'shared/utils/params';
import { useFormFields } from 'entities/form-field';

const EXCLUDE_PARAMS = [
  'rows', 
  'page',
  'tab',
]


export const useFilter = (conf?: {formId: string, initFilters: any}) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector(state => state.filtersReducer);

  const setFormFilters = (newFilters: IFilter[]) => {
    dispatch(filtersActions.setFormFilters(newFilters));
    localStorage.setItem('filters', JSON.stringify([...newFilters]));
  };

  const updateFilters = (newFilters: IFilter[]) => {
    const updateFilters = unionFilters(filters, newFilters);
    setFormFilters(updateFilters);
  };

  const getUpdatedFilters = (newFilters: IFilter[], returnValue?: boolean) => {
    return unionFilters(filters, newFilters);
  };

  const clearFormFilters = () => {
    dispatch(filtersActions.setFormFilters([]));
    localStorage.setItem('filters', JSON.stringify([]));
  };

  const setFiltersFromParams = (formId: string, paramsString: string) => {
    const paramsJson = searchParamsToObjectWithRepeated(paramsString);
    let _filters: IFilter[] = JSON.parse(localStorage.getItem('filters') ?? '[]');
    let _formFilters: IFilter[] = _filters.filter(f => f.formId === formId);
    let _restFilters: IFilter[] = _filters.filter(f => f.formId !== formId);
    const updateFilters: IFilter[] = [];
    Object.keys(paramsJson)
    .filter(paramKey => !EXCLUDE_PARAMS.includes(paramKey))
    .forEach(paramKey => {

      const filter = _formFilters.find(f => f.formId == formId && f.name == paramKey);
      let newFilter: IFilter = {} as IFilter;
      // @ts-ignore
      const newValue = typeof(paramsJson[paramKey]) === 'string' ? paramsJson[paramKey].replace('+', ' ') : paramsJson[paramKey]
      if (filter) {
        _restFilters = _restFilters.filter(f => !(f.formId === formId && f.name === paramKey));
        newFilter = {
          ...filter,
          value: newValue
        };
      } else {
        newFilter = {
          formId: formId,
          name: paramKey,
          value: newValue,
          label: paramKey
        }
      }
      updateFilters.push(newFilter);
    });
    setFormFilters([..._restFilters, ...updateFilters]);
  }

  const unionFilters = (oldFilters: IFilter[], newFilters: IFilter[]) => {
    let updateFilters = [...oldFilters];
    newFilters.forEach(nF => {
      const findIndex = updateFilters.findIndex(f => f.formId == nF.formId && f.name == nF.name);
      if (findIndex != -1) {
        if (nF.value) {
          updateFilters.splice(findIndex, 1, nF);
        } else {
          updateFilters.splice(findIndex, 1);
        }
      } else {
        if (nF.value) {
          updateFilters = [...updateFilters, nF]
        }
      }
    })

    return updateFilters;
  }

  const getFormFilters = (formId: string, options?: { excludeNames: string[] }) => {
    let formFilters: IFilter[] = [];
    if (!formId) return formFilters;

    formFilters =  filters.filter(f => f.formId === formId) || null;

    if (formFilters && options?.excludeNames) {
      formFilters = formFilters.filter(f => !options.excludeNames.includes(f.name));
    }

    return formFilters;
  }

  const getFormFilterByName = (formId: string, name: string) => {
    return getFormFilters(formId).find(f => f.name == name);
  }

  const setFormFilterByName = (formId: string, name: string, value: any) => {
    let updatedFilter = [...getFormFilters(formId)].map(f => f.name === name ? ({...f, ...value}) : f);
    setFormFilters([...filters, ...updatedFilter]);
  }

  const toParamsByFormId = (formId: string) => {
    return filters.filter(f => f.formId == formId).reduce((prev, curr) => ({...prev, [curr.name]: curr.value}), {});
  }

  const toParamsFromArray = (filters: IFilter[]) => {
    return filters.reduce((prev, curr) => ({...prev, [curr.name]: curr.value}), {});
  }

  const removeFilter = (formId: string, name: string | string[]) => {
    let updateFilters: IFilter[] = [];
    if (Array.isArray(name)) {
      updateFilters = filters.filter(f => !(f.formId === formId && name.includes(f.name)));
    } else {
      updateFilters = filters.filter(f => !(f.formId === formId && f.name === name));
    }
    setFormFilters(updateFilters);
  }

  const removeFormFilter = (formId: string) => {
    const updateFilters: IFilter[] = filters.filter(f => f.formId !== formId);
    setFormFilters(updateFilters);
  }

  const isEmpty = () => Object.keys(filters).length == 0;

  React.useEffect(() => {
    if (!conf?.formId || !conf?.initFilters) return;
    if(filters.length != 0) return;
    setFiltersFromParams(conf.formId, conf.initFilters);
    // initFilters();
  }, [])


  return {
    filters,
    toParamsByFormId,
    toParamsFromArray,
    getFormFilterByName,
    setFormFilterByName,
    getFormFilters,
    setFormFilters,
    updateFilters,
    clearFormFilters,
    isEmpty,
    removeFilter,
    removeFormFilter,
    getUpdatedFilters
  }
}