const MIN_ENG_UPPER_CHAR_CODE = 65;
const MAX_ENG_UPPER_CHAR_CODE = 90;
const MIN_RUS_UPPER_CHAR_CODE = 1040;
const MAX_RUS_UPPER_CHAR_CODE = 1071;

const MIN_ENG_LOWER_CHAR_CODE = 97;
const MAX_ENG_LOWER_CHAR_CODE = 122;
const MIN_RUS_LOWER_CHAR_CODE = 1072;
const MAX_RUS_LOWER_CHAR_CODE = 1103;

const MIN_DIGIT_CHAR_CODE = 48;
const MAX_DIGIT_CHAR_CODE = 57;

enum ValidationType {
  anyUppercase = 'any_uppercase',
  anyLowercase = 'any_lowercase',
  anyDigits = 'any_digits',
  minMax = 'min_max',
  specials = 'specials',
}

export const validate = (validation: any[], value?: string, options?: { required: boolean }): [boolean, string] => {
  let lastHelperText = "";
  if (validation.length === 0) return [true, lastHelperText];
  if (options?.required && !value) return [false, "Обязательное поле"];

  if (!value) return [true, lastHelperText];

  const validList: boolean[] = [];
  
  validation.forEach(v => {
    let isValid = false;
    switch(v.type) {
      case ValidationType.anyUppercase:
        isValid = Validation.anyUppercase(value)
        validList.push(isValid);
        if (!isValid) {
          lastHelperText = v.helper_text;
        }
        break;
      case ValidationType.anyLowercase:
        isValid = Validation.anyLowercase(value)
        validList.push(isValid);
        if (!isValid) {
          lastHelperText = v.helper_text;
        }
        break;
      case ValidationType.anyDigits:
        isValid = Validation.anyDigits(value)
        validList.push(isValid);
        if (!isValid) {
          lastHelperText = v.helper_text;
        }
        break;
      case ValidationType.minMax:
        isValid = Validation.minMax(value, { max: v.max, min: v.min })
        validList.push(isValid);
        if (!isValid) {
          lastHelperText = v.helper_text;
        }
        break;
      case ValidationType.specials:
        isValid = Validation.anySpecials(value, { characters: v.characters })
        validList.push(isValid);
        if (!isValid) {
          lastHelperText = v.helper_text;
        }
        break;
    }
  })

  const isFullValid = validList.reduce((prev, curr) => prev && curr);
  return [isFullValid, lastHelperText];
}


class Validation {
  static anyUppercase(value: string) {
    let isValid = false;
    
    for(let i = 0; i < value.length; i++) {
      const charCode = value.charCodeAt(i);
      if (MIN_ENG_UPPER_CHAR_CODE <= charCode && charCode <= MAX_ENG_UPPER_CHAR_CODE ||
          MIN_RUS_UPPER_CHAR_CODE <= charCode && charCode <= MAX_RUS_UPPER_CHAR_CODE) 
      {
        isValid = true;
        break;
      }
    }
    return isValid;
  }

  static anyLowercase(value: string) {
    let isValid = false;

    for(let i = 0; i < value.length; i++) {
      const charCode = value.charCodeAt(i);
      if (MIN_ENG_LOWER_CHAR_CODE <= charCode && charCode <= MAX_ENG_LOWER_CHAR_CODE ||
          MIN_RUS_LOWER_CHAR_CODE <= charCode && charCode <= MAX_RUS_LOWER_CHAR_CODE) 
      {
        isValid = true;
        break;
      }
    }
    return isValid;
  }

  static anyDigits(value: string) {
    let isValid = false;

    for(let i = 0; i < value.length; i++) {
      const charCode = value.charCodeAt(i);
      if (MIN_DIGIT_CHAR_CODE <= charCode && charCode <= MAX_DIGIT_CHAR_CODE) 
      {
        isValid = true;
        break;
      }
    }
    return isValid;
  }

  static minMax(value: string, args: {min: number, max: number}) {
    let isValid = false;
    const { max, min } = args;
    if (min <= value.length && value.length <= max) {
      isValid = true;
    }

    return isValid;
  }

  static specials(value: string, args: {characters: string}) {
    const { characters } = args;

    let isValid = true;
    for(let i = 0; i < characters.length; i++) {
      const specChar = characters[i];
      if (!value.includes(specChar)) {
        isValid = false;
      }
    }

    return isValid;
  }

  static anySpecials(value: string, args: {characters: string}) {
    const { characters } = args;

    let isValid = false;
    for(let i = 0; i < characters.length; i++) {
      const specChar = characters[i];
      if (value.includes(specChar)) {
        isValid = true;
        break;
      }
    }

    return isValid;
  }

}