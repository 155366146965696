import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment, { Moment } from 'moment';
import { DatePicker, LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/ru';

import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { Stack } from '@mui/material';
import { useFilter } from 'entities/filter';
import { useFormFields } from 'entities/form-field';

const sxProps = {
  width: '100%',
  // height: 40,
  mr: 1, 
  '& .MuiInputBase-root': {
    height: 40,
  },
  '& .MuiFormLabel-root.MuiInputLabel-root[data-shrink="false"]': {
    top: '-6px',
  },
  // mb: 2,
  // '& label': {
  //   top: '-5px',
  //   fontSize: '14px',
  // },
  // '& .MuiInputBase-root': {
  //   height: 30,
  //   // border: '1px solid black'
  // },
  // '& .MuiInputBase-input': {
  //   padding: '10px 12px',
  // },
  // '& legend': {
  //   fontSize: '0.65rem !important'
  // },
  // '& fieldset': {
  //   border: '1px solid black'
  // }
}

interface DatePickerComponentProps {
  componentName?: string;
  name?: string;
  label?: string;
  formId?: string;
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  componentName,
  name,
  formId, 
  label, 
}) => {
  moment.locale('ru');
  const filter = useFilter();
  const formFields = useFormFields();

  const [date, setDate] = React.useState<Moment | null>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [dateValue, setDateValue] = React.useState<string>('');

  React.useEffect(() => {
    if (formId && name) {
      const dateValue = filter.getFormFilterByName(formId, name)?.value || formFields.getFormFieldByName(name, formId)?.value || "";
      if (dateValue) {
        setDateValue(dateValue);
        const mDate = moment(dateValue);
        setDate(mDate);
      }
    }
  }, [formId, name, filter.filters])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">  
      <Stack 
        direction='row' 
        alignItems='center' 
        spacing={1} 
        sx={{ p: 1, mb: 1 }}
        data-form-id={formId}
        data-name={name}
        data-value={JSON.stringify(dateValue)}
        data-label={label}
        data-component={componentName}
      >
        <DatePicker
          sx={sxProps}
          value={date}
          open={open}
          
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              label: label
            }
          }}
          slots={{
            openPickerButton: () => date == null 
            ? <EventIcon sx={{cursor: 'pointer'}} onClick={() => setOpen(!open)} />
            : <CloseIcon sx={{cursor: 'pointer'}} onClick={() => {
              setDate(null);
              setDateValue('');
            }}/>
          }}
          onChange={(newValue) => {
            if (newValue) {
              if (newValue.isValid()) {
                setDate(newValue);
                setDateValue(newValue.format('YYYY-MM-DD'));
              }
            } else {
              setDate(null);
              setDateValue('');
            }
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export const buildDatePickerComponent = (component: any) => (
  <DatePickerComponent 
    componentName={component.component}
    formId={component['form_id']}
    label={component['label']}
    name={component['name']}
  />
)