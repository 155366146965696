import React, { useId } from "react";
import { useFilter } from "entities/filter";
import { useFormFields } from "entities/form-field";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

interface RadioButtonOption {
  value: string;
  label: string;
}

export const RadioGroupComponent: React.FC<{
  componentName: string,
  name?: string,
  label?: string,
  formId?: string,
  options?: RadioButtonOption[]
  required?: boolean;
}> = ({ 
  componentName, 
  name,
  label,
  formId,
  options = [],
  required = false,
}) => {
  const radioGroupId = useId();
  const filter = useFilter();
  const formFields = useFormFields();

  // @ts-ignore
  const [value, setValue] = React.useState(filter.getFormFilterByName(formId, name)?.value ?? "");
  const [radioLabel, setRadioLabel] = React.useState("");
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    const newLabel = options.find(opt => opt.value === newValue)?.label || label || "";
    setValue(newValue);
    setRadioLabel(newLabel);

    formFields.updateFormField({
      form_id: formId ?? "",
      name: name || "",
      value: newValue,
    })
  };
  

  React.useEffect(() => {
    if (formId && name) {
      setValue(filter.getFormFilterByName(formId, name)?.value || formFields.getFormFieldByName(name, formId)?.value || "");
    }
  }, [formId, name, filter.filters])

  return (
    <FormControl
      data-form-id={formId}
      data-name={name}
      data-value={JSON.stringify(value)}
      data-label={label}
      data-select={radioLabel}
      data-component={componentName}
      sx={{ p: 1 }}
    >
      {/* <FormLabel id={radioGroupId}>{label}</FormLabel> */}
      <RadioGroup 
        row 
        aria-labelledby={radioGroupId}
        value={value}
        onChange={handleChange}
      >
        {options.map(opt =>
          <FormControlLabel value={opt.value} control={<Radio />} label={opt.label} />
        )}
      </RadioGroup>
    </FormControl>
  )
}

export const buildRadioGroupComponent = (component: any) => (
  <RadioGroupComponent 
    componentName={component.component}
    name={component['name']}
    label={component['label']}
    options={component['options']}
    formId={component['form_id']}
  />
)