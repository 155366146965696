import Typography from "@mui/material/Typography";

import './style.css';
import { buildComponents } from "shared/utils/build";

interface HeadingComponentProps {
  children?: React.ReactNode | string;
  componentName: string;
  type?: string;
  align?: string;
  height?: number | string;
  width?: number | string;
  margin?: number[];
  padding?: number[];
}

export const HeadingComponent: React.FC<HeadingComponentProps> = ({ 
  children, 
  componentName, 
  type= "h3",
  align='left',
  height = 'unset',
  width = 'unset',
  margin = [0, 0, 0, 0],
  padding = [0, 0, 0, 0],
}) => {
  return (
    // @ts-ignore
    <Typography
      componentName={componentName}
      className="heading"
      // @ts-ignore
      variant={type}
      align={align}
      sx={{
        margin: margin.join('px ') + 'px',
        padding: padding.join('px ') + 'px',
        height: height,
        width: width,
      }}
    >
      {children}
    </Typography>
  )
}

export const buildHeadingComponent = (component: any) => (
  <HeadingComponent 
    componentName={component.component} 
    type={component['type']} 
    align={component['align']}
    height={component['height']}
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
  >
    {component['content'] != undefined ? component['content'].map((c: any) => buildComponents(c)) : (component['value'] ?? null)}
  </HeadingComponent>
)