import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment, { Moment } from 'moment';
import { DatePicker, LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/ru';

import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { Stack } from '@mui/material';
import { useFilter } from 'entities/filter';
import { useFormFields } from 'entities/form-field';

const sxProps = {
  width: '100%',
  // height: 40,
  mr: 1, 
  '& .MuiInputBase-root': {
    height: 40,
  },
  '& .MuiFormLabel-root.MuiInputLabel-root[data-shrink="false"]': {
    top: '-6px',
  },
  // mb: 2,
  // '& label': {
  //   top: '-5px',
  //   fontSize: '14px',
  // },
  // '& .MuiInputBase-root': {
  //   height: 30,
  //   // border: '1px solid black'
  // },
  // '& .MuiInputBase-input': {
  //   padding: '10px 12px',
  // },
  // '& legend': {
  //   fontSize: '0.65rem !important'
  // },
  // '& fieldset': {
  //   border: '1px solid black'
  // }
}

interface DatetimePickerComponentProps {
  componentName?: string;
  name?: string;
  label?: string;
  formId?: string;
}

export const DatetimePickerComponent: React.FC<DatetimePickerComponentProps> = ({
  componentName,
  name,
  formId, 
  label, 
}) => {
  moment.locale('ru');
  const filter = useFilter();
  const formFields = useFormFields();
  const [date, setDate] = React.useState<Moment | null>();
  const [time, setTime] = React.useState<Moment | null>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [dateTimeValue, setDateTimeValue] = React.useState<string>('');

  const getCombineDateAndTime = (value: Moment | null, type: string, defaultValue?: Moment) => {
    let combineDateTime = moment();

    let inputValue = value ? value : defaultValue;
    if (!inputValue) return combineDateTime;

    if (type == 'time') {
      if (date != null) {
        combineDateTime = moment(date.format('DD.MM.YYYY') + ' ' + inputValue.format('HH:mm a'), 'DD.MM.YYYY HH:mm a');
      } else {
        combineDateTime = inputValue;
      }
    } else {
      if (time != null) {
        combineDateTime = moment(inputValue.format('DD.MM.YYYY') + ' ' + time.format('HH:mm a'), 'DD.MM.YYYY HH:mm a');
      } else {
        combineDateTime = inputValue;
      }
    }

    return combineDateTime;
  }

  React.useEffect(() => {
    if (formId && name) {
      const dateTimeValue = filter.getFormFilterByName(formId, name)?.value || formFields.getFormFieldByName(name, formId)?.value || "";
      if (dateTimeValue) {
        setDateTimeValue(dateTimeValue);
        const mDateTime = moment(dateTimeValue);
        setTime(mDateTime);
        setDate(mDateTime);
      }
    }
  }, [formId, name, filter.filters])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">  
      <Stack 
        direction='row' 
        alignItems='center' 
        spacing={1} 
        sx={{ p: 1, mb: 1 }}
        data-form-id={formId}
        data-name={name}
        data-value={JSON.stringify(dateTimeValue)}
        data-label={label}
        data-component={componentName}
      >
        <DatePicker
          sx={sxProps}
          value={date}
          open={open}
          
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              label: label
            }
          }}
          slots={{
            openPickerButton: () => date == null 
            ? <EventIcon sx={{cursor: 'pointer'}} onClick={() => setOpen(!open)} />
            : <CloseIcon sx={{cursor: 'pointer'}} onClick={() => {
              setDate(null);
              setTime(null);
              setDateTimeValue('')
            }}/>
          }}
          onChange={(newValue) => {
            if (newValue) {
              if (newValue.isValid()) {
                setDate(newValue);
              }
              
              const newCombineDateAndTime = getCombineDateAndTime(newValue, 'date');
              if (newCombineDateAndTime.isValid()) {
                setDateTimeValue(newCombineDateAndTime?.toISOString(true).split('+')[0]);
              }
            } else {
              setDate(newValue);
              setDateTimeValue('');
            }
          }}
        />
        <TimeField
          sx={{...sxProps, width: 120, mr: 0}}
          slotProps={{
            textField: {
              label: 'Время'
            }
          }}
          value={time}
          onChange={(newValue) => {
            if (newValue) {
              if (newValue.isValid()) {
                setTime(newValue);
              }
              
              const newCombineDateAndTime = getCombineDateAndTime(newValue, 'time');
              if (newCombineDateAndTime.isValid()) {
                setDateTimeValue(newCombineDateAndTime?.toISOString(true).split('+')[0]);
              }
            } else {
              setTime(newValue);
              if (date !== null) {
                const newCombineDateAndTime = getCombineDateAndTime(newValue, 'time', moment().set('hours', 23).set('minutes', 59));
                if (newCombineDateAndTime.isValid()) {
                  setDateTimeValue(newCombineDateAndTime?.toISOString(true).split('+')[0]);
                }
              } else {
                setDateTimeValue('');
              }
              
            }
          }}
          format="HH:mm"
        />
      </Stack>
    </LocalizationProvider>
  );
}

export const buildDatetimePickerComponent = (component: any) => (
  <DatetimePickerComponent 
    componentName={component.component}
    formId={component['form_id']}
    label={component['label']}
    name={component['name']}
  />
)