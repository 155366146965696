import { AppDispatch } from "app/store";
import { mainMenuComponentActions } from "./model";
import { AdminApi } from "shared/http/admin-api";

export function getDrawerMenuComponent () {
  return async (dispatch: AppDispatch) => {
    try {
      // dispatch(mainMenuComponentActions.drawerMenuComponentRequestRestore());
      dispatch(mainMenuComponentActions.drawerMenuComponentRequest());
      const response = await AdminApi.getMainMenu();
      dispatch(mainMenuComponentActions.drawerMenuComponentRequestSuccess(response.data.resourceRoutes));
    } catch (e: any) {
      // dispatch(mainMenuComponentActions.tableComponentRequestError(e.response?.data.error_description || "ERROR"));
      dispatch(mainMenuComponentActions.drawerMenuComponentRequestError("ERROR"));
    }
  }
}