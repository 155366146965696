import { Box, Stack } from "@mui/material";
import { useFilter } from "entities/filter";
import { useFormFields } from "entities/form-field";
import { ListSelectorAutocomplete } from "./select";
import React from "react";

export interface ISelectValue {
  label: string;
  value: string;
}

interface SelectComponentProps  {
  componentName?: string;
  label?: string;
  name?: string;
  tooltip?: string;
  formId?: string;
  isMultiple?: boolean;
  options?: ISelectValue[];
  addictedBy?: string;
  mapping?: { [addictedValue: string]: ISelectValue[] };
}

export const SelectComponent: React.FC<SelectComponentProps> = ({
  componentName,
  addictedBy,
  mapping, 
  formId,
  tooltip,
  isMultiple = false,
  name = '', 
  label = '', 
  options = []
}) => {
  const filter = useFilter();
  const formFields = useFormFields();

  const [addictedValue, setAddictedValue] = React.useState<string>("#");
  
  const [value, setValue] = React.useState<string[] | string | null>(isMultiple ? [] : null);
  
  const getPlainValue = (value: ISelectValue[] | ISelectValue | null) => {
    if(Array.isArray(value)) {
      return value.map(v => v.value);
    } else {
      return value ? value.value : null;
    }
  }
  
  const handleChangeValue = (newValue: any) => {
    setValue(getPlainValue(newValue));

    formFields.updateFormField({
      form_id: formId ?? "",
      name,
      value: getPlainValue(newValue)
    })
  }

  const getItems = React.useMemo(() => {
    if (addictedBy) {
      if (addictedValue && mapping) {
        return mapping[addictedValue] || [];
      } else {
        return [];
      }
    } else {
      return options;
    }
  }, [addictedValue])

  // const getTranslateItems = () => {
  //   if (addictedBy && mapping && addictedValue) {
  //     return (mapping[addictedValue] || []).reduce((prev, curr) => ({...prev, [curr.value]: curr.label }), {});
  //   } else {
  //     return options.reduce((prev, curr) => ({...prev, [curr['value']]: curr['label'] }), {});
  //   }
  // }

  React.useEffect(() => {
    if (formId && name) {
      const fromFilter = filter.getFormFilterByName(formId, name)?.value;
      const fromFormFields = formFields.getFormFieldByName(name, formId)?.value;
      
      if (isMultiple) {
        setValue(fromFormFields || []);
      } else {
        setValue(fromFormFields);
      }
    }
  }, [formId, name, filter.filters, formFields.getFormFieldByName(name, formId || "")])

  React.useEffect(() => {
    if (formId && addictedBy) {
      // const fromFilter = filter.getFormFilterByName(formId, addictedBy)?.value;
      const fromFormFields = formFields.getFormFieldByName(addictedBy, formId)?.value;
      const newAddictedFieldValue = fromFormFields;
      setAddictedValue(newAddictedFieldValue);
      
    }
  }, [formId, addictedBy, formFields.getFormFieldByName(addictedBy ?? "", formId ?? "")])


  return (
    <Box sx={{ p: 1 }}>
      <ListSelectorAutocomplete 
        label={label}
        name={name}
        formId={formId}
        tooltip={tooltip}
        isMultiple={isMultiple}
        componentName={componentName}
        addictedBy={addictedBy}
        addictedValue={addictedValue}
        items={getItems}
        // translateItems={getTranslateItems()}
        paramName={name}
        value={value}
        setValue={handleChangeValue}
        disabled={Boolean(addictedBy) && !addictedValue}
      />
    </Box>
  );
}

export const buildSelectComponent = (component: any) => (
  <SelectComponent 
    componentName={component.component}
    formId={component['form_id']}
    label={component['label']}
    name={component['name']}
    options={component['options']}
    tooltip={component['tooltip']}
    addictedBy={component['addicted_by']}
    mapping={component['mapping']}
    isMultiple={component['is_multiple']}
  />
)