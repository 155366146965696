import { PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "shared/models/model";
import { IAuthState } from "../state";


export const logoutReducers = {
  logoutRequest(state: IAuthState) {
    state.logoutRequestStatus.isLoading = true;
  },
  logoutRequestSuccess(state: IAuthState) {
    state.logoutRequestStatus.isLoading = false;
    state.logoutRequestStatus.error = '';
    state.logoutRequestStatus.success = true;
  },
  logoutRequestError(state: IAuthState, action: PayloadAction<any>) {
    state.logoutRequestStatus.isLoading = false;
    state.logoutRequestStatus.success = false;
    state.logoutRequestStatus.error = action.payload;
  },
  logoutRequestRestore(state: IAuthState) {
    state.logoutRequestStatus = {} as RequestStatus;
  }
}