import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <>
      {value !== index 
        ? null
        : <Stack
            role="tabpanel"
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            sx={{
              width: '100%',
              // height: '100%',
              flexGrow: 1,
            }}
            {...other}
          >
            {value === index && (
              <Stack sx={{ width: '100%', flexGrow: 1 }}>
                {children}
              </Stack>
            )}
          </Stack>
          }
    </>
  );
}

export default TabPanel;