import React from 'react';
import { TableBody } from "@mui/material";
import { StyledTableCell, getCellByColumnType } from "./table-cell";
import { StyledTableRow } from "./table-row";
import { Column } from 'entities/table-component/model/state';


interface TableBodyContentProps {
  rows: any[],
  header: Column[],
  page: number;
  rowsPerPage: number;
  isCommonTable?: boolean;
}

const getAligh = (field: string) => {
  const leftAlign = ['payment_at', 'short_id', 'storage_location', 'storage', 'full_name_user', 'full_name_employee_get', 'full_name_employee_give'];
  const rightAlign = ['price', 'order_items_amount', 'sum_of_payment'];
  if (leftAlign.includes(field)) {
    return 'left'
  } else if(rightAlign.includes(field)) {
    return 'right';
  } else {
    return 'center';
  }
}



export const TableBodyContent: React.FC<TableBodyContentProps> = ({
  rows, 
  header, 
  page, 
  rowsPerPage,
  isCommonTable = false
}) => {

  return (
    <>
      <TableBody>
        {rows
          .map((row, index) => {
            return (
              <StyledTableRow 
                hover 
                role="checkbox" 
                tabIndex={-1}
              >
                
                <StyledTableCell align='center' sx={{ minWidth: 'max-content' }}>
                  {(page - 1) * rowsPerPage +  index + 1}
                </StyledTableCell>
              
                {header.map((headerCell) => {
                  return (
                    <StyledTableCell 
                      key={`${row[headerCell.name]}_${headerCell.name}`} 
                      align={getAligh(headerCell.name)} 
                      sx={{ 
                        height: 50,
                        minWidth: isCommonTable ? 'unset' : 'max-content',
                        whiteSpace: isCommonTable ? 'initial' : 'nowrap',
                      }}
                    >
                      {getCellByColumnType(headerCell.value_type, row[headerCell.name], headerCell, row)}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
      </TableBody>
    </> 
  )
}