import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment, { Moment } from 'moment';
import { DatePicker, LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/ru';

import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { Stack } from '@mui/material';
import { useFilter } from 'entities/filter';
import { useFormFields } from 'entities/form-field';

const sxProps = {
  width: '100%',
  // height: 40,
  mr: 1, 
  '& .MuiInputBase-root': {
    height: 40,
  },
  '& .MuiFormLabel-root.MuiInputLabel-root[data-shrink="false"]': {
    top: '-6px',
  },
  // mb: 2,
  // '& label': {
  //   top: '-5px',
  //   fontSize: '14px',
  // },
  // '& .MuiInputBase-root': {
  //   height: 30,
  //   // border: '1px solid black'
  // },
  // '& .MuiInputBase-input': {
  //   padding: '10px 12px',
  // },
  // '& legend': {
  //   fontSize: '0.65rem !important'
  // },
  // '& fieldset': {
  //   border: '1px solid black'
  // }
}

interface DatetimeRangeComponentProps {
  componentName?: string;
  name?: string[];
  label?: string[];
  formId?: string;
}

export const DatetimeRangeComponent: React.FC<DatetimeRangeComponentProps> = ({
  componentName,
  name = ['#', '#'],
  label = ['#', '#'], 
  formId , 
}) => {
  moment.locale('ru');
  const filter = useFilter();
  const formFields = useFormFields();
  const [dateFrom, setDateFrom] = React.useState<Moment | null>();
  const [timeFrom, setTimeFrom] = React.useState<Moment | null>();
  const [openFrom, setOpenFrom] = React.useState<boolean>(false);
  const [dateTimeValueFrom, setDateTimeValueFrom] = React.useState<string>('');

  const [dateTo, setDateTo] = React.useState<Moment | null>();
  const [timeTo, setTimeTo] = React.useState<Moment | null>();
  const [openTo, setOpenTo] = React.useState<boolean>(false);
  const [dateTimeValueTo, setDateTimeValueTo] = React.useState<string>('');

  const getCombineDateAndtimeFrom = (value: Moment | null, type: string, defaultValue?: Moment) => {
    let combineDateTime = moment();
    let inputValue = value ? value : defaultValue;
    if (!inputValue) return combineDateTime;

    if (type == 'time') {
      if (dateFrom != null) {
        combineDateTime = moment(dateFrom.format('DD.MM.YYYY') + ' ' + inputValue.format('HH:mm a'), 'DD.MM.YYYY HH:mm a');
      } else {
        combineDateTime = inputValue;
      }
    } else {
      if (timeFrom != null) {
        combineDateTime = moment(inputValue.format('DD.MM.YYYY') + ' ' + timeFrom.format('HH:mm a'), 'DD.MM.YYYY HH:mm a');
      } else {
        combineDateTime = inputValue;
      }
    }

    return combineDateTime;
  }

  const getCombineDateAndtimeTo = (value: Moment | null, type: string, defaultValue?: Moment) => {
    let combineDateTime = moment();

    let inputValue = value ? value : defaultValue;
    if (!inputValue) return combineDateTime;

    if (type == 'time') {
      if (dateTo != null) {
        combineDateTime = moment(dateTo.format('DD.MM.YYYY') + ' ' + inputValue.format('HH:mm a'), 'DD.MM.YYYY HH:mm a');
      } else {
        combineDateTime = inputValue;
      }
    } else {
      if (timeTo != null) {
        combineDateTime = moment(inputValue.format('DD.MM.YYYY') + ' ' + timeTo.format('HH:mm a'), 'DD.MM.YYYY HH:mm a');
      } else {
        combineDateTime = inputValue.set('hours', 23).set('minutes', 59);
      }
    }

    return combineDateTime;
  }

  React.useEffect(() => {
    if (formId && name && name.length != 0) {
      const dateTimeValueFrom = filter.getFormFilterByName(formId, name[0])?.value  || formFields.getFormFieldByName(name[0], formId)?.value || "";
      const dateTimeValueTo = filter.getFormFilterByName(formId, name[1])?.value || formFields.getFormFieldByName(name[1], formId)?.value || "";
      if (dateTimeValueFrom) {
        setDateTimeValueFrom(dateTimeValueFrom);
        const mDateTimeFrom = moment(dateTimeValueFrom);
        setTimeFrom(mDateTimeFrom);
        setDateFrom(mDateTimeFrom);
      }

      if (dateTimeValueTo) {
        setDateTimeValueTo(dateTimeValueTo);
        const mDateTimeTo = moment(dateTimeValueTo);
        setTimeTo(mDateTimeTo);
        setDateTo(mDateTimeTo);
      }
    }
  }, [formId, name, filter.filters])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">  
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack 
          direction='row' 
          alignItems='center' 
          spacing={1} 
          sx={{ p: 1, mb: 1 }}
          data-form-id={formId}
          data-name={name[0]}
          data-value={JSON.stringify(dateTimeValueFrom)}
          data-label={label[0]}
          data-component={componentName}
        >
          <DatePicker
            sx={sxProps}
            value={dateFrom}
            open={openFrom}
            
            onClose={() => setOpenFrom(false)}
            slotProps={{
              textField: {
                label: label[0],
              }
            }}
            slots={{
              openPickerButton: () => dateFrom == null 
              ? <EventIcon sx={{cursor: 'pointer'}} onClick={() => setOpenFrom(!openFrom)} />
              : <CloseIcon sx={{cursor: 'pointer'}} onClick={() => {
                setDateFrom(null);
                setTimeFrom(null);
                setDateTimeValueFrom('')
              }}/>
            }}
            onChange={(newValue) => {
              
              if (newValue) {
                if (newValue.isValid()) {
                  setDateFrom(newValue);
                }
                
                const newCombineDateAndTime = getCombineDateAndtimeFrom(newValue, 'date');
                if (newCombineDateAndTime.isValid()) {
                  setDateTimeValueFrom(newCombineDateAndTime?.toISOString(true).split('+')[0]);
                }
              } else {
                setDateFrom(newValue);
                setDateTimeValueFrom('');
              }
            }}
          />
          <TimeField
            sx={{...sxProps, width: 120, mr: 0}}
            slotProps={{
              textField: {
                label: 'Время'
              }
            }}
            value={timeFrom}
            onChange={(newValue) => {
              if (newValue) {
                if (newValue.isValid()) {
                  setTimeFrom(newValue);
                }
                
                const newCombineDateAndTime = getCombineDateAndtimeFrom(newValue, 'time');
                if (newCombineDateAndTime.isValid()) {
                  setDateTimeValueFrom(newCombineDateAndTime?.toISOString(true).split('+')[0]);
                }
              } else {
                setTimeFrom(newValue);
                if(dateFrom !== null) {
                  const newCombineDateAndTime = getCombineDateAndtimeFrom(newValue, 'time', moment().set('hours', 0).set('minutes', 0));
                  if (newCombineDateAndTime.isValid()) {
                    setDateTimeValueFrom(newCombineDateAndTime?.toISOString(true).split('+')[0]);
                  }
                } else {
                  setDateTimeValueFrom('');
                }
                
              }
            }}
            format="HH:mm"
          />
        </Stack>


        <Stack 
          direction='row' 
          alignItems='center' 
          spacing={1} 
          sx={{ p: 1, mb: 1 }}
          data-form-id={formId}
          data-name={name[1]}
          data-value={JSON.stringify(dateTimeValueTo)}
          data-label={label[1]}
          data-component={componentName}
        >
          <DatePicker
            sx={sxProps}
            value={dateTo}
            open={openTo}
            
            onClose={() => setOpenTo(false)}
            slotProps={{
              textField: {
                label: label[1]
              }
            }}
            slots={{
              openPickerButton: () => dateTo == null 
              ? <EventIcon sx={{cursor: 'pointer'}} onClick={() => setOpenTo(!openTo)} />
              : <CloseIcon sx={{cursor: 'pointer'}} onClick={() => {
                setDateTo(null);
                setTimeTo(null);
                setDateTimeValueTo('');
              }}/>
            }}
            onChange={(newValue) => {
              if (newValue) {
                if (newValue.isValid()) {
                  setDateTo(newValue);
                }
                
                const newCombineDateAndTime = getCombineDateAndtimeTo(newValue, 'date');
                if (newCombineDateAndTime.isValid()) {
                  setDateTimeValueTo(newCombineDateAndTime?.toISOString(true).split('+')[0]);
                }
              } else {
                setDateTo(newValue);
                setDateTimeValueTo('');
              }
            }}
          />
          <TimeField
            sx={{...sxProps, width: 120, mr: 0}}
            slotProps={{
              textField: {
                label: 'Время'
              }
            }}
            value={timeTo}
            onChange={(newValue) => {
              console.log(newValue);
              if (newValue) {
                if (newValue.isValid()) {
                  setTimeTo(newValue);
                }
                
                
                const newCombineDateAndTime = getCombineDateAndtimeTo(newValue, 'time');
                if (newCombineDateAndTime.isValid()) {
                  setDateTimeValueTo(newCombineDateAndTime?.toISOString(true).split('+')[0]);
                }
              } else {
                setTimeTo(newValue);
                if (dateTo !== null) {
                  const newCombineDateAndTime = getCombineDateAndtimeTo(newValue, 'time', moment().set('hours', 23).set('minutes', 59));
                  if (newCombineDateAndTime.isValid()) {
                    setDateTimeValueTo(newCombineDateAndTime?.toISOString(true).split('+')[0]);
                  }
                } else {
                  setDateTimeValueTo('');
                }
                
              }
            }}
            format="HH:mm"
          />
        </Stack>
      </Stack>
      
    </LocalizationProvider>
  );
}

export const buildDatetimeRangeComponent = (component: any) => (
  <DatetimeRangeComponent 
    componentName={component.component}
    formId={component['form_id']}
    label={component['label']}
    name={component['name']}
  />
)