import jwt_decode from "jwt-decode";
import { User } from "shared/models/model";
import { UserRole } from "shared/enum";

export const useUser = () => {
  const user: User = localStorage.getItem('access_token') ? jwt_decode(localStorage.getItem('access_token') ?? "") : {} as User;

  const isRepresentative = () => user.roles['admin-panel'].includes(UserRole.representative);

  return {...user, isRepresentative};
}