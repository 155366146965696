import { Box } from "@mui/material";
import './style.css';

export const ImageComponent: React.FC<{
  value?: string | any,
  componentName: string,
  width?: string | number,
  height?: string | number,
  margin?: number[],
  padding?: number[],
}> = ({ 
  value, 
  componentName, 
  width = '150px', 
  height = "100px",
  margin = [0, 0, 0, 0],
  padding = [0, 0, 0, 0]
}) => {

  return (
    //@ts-ignore
    <Box
      className="img-component-wrapper"
      componentName={componentName}
      width={width}
      height={height}
      sx={{
        margin: margin.join('px ') + 'px',
        padding: padding.join('px ') + 'px',
      }}
    >
      <Box 
        sx={{ 
        width: '100%', 
        height: '100%',
        backgroundColor: '#00000033',
        overflow: 'hidden',
        borderRadius: '4px'
      }}>
        <img src={value}/>
      </Box>
    </Box>
  )
}

export const buildImageComponent = (component: any, value?: any) => (
  <ImageComponent 
    componentName={component.component} 
    value={value ?? component['value']}
    width={component['width']}
    height={component['height']}
    margin={component['margin']}
    padding={component['padding']}
  />
)