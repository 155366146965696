import { useAppDispatch, useAppSelector } from "app/store/lib";
import { getDrawerMenuComponent as getMainMenu } from "./api";
import { useEffect } from "react";

export const useDrawerMainMenu = () => {
  const dispatch = useAppDispatch();
  const {
    mainMenuComponentReducer: {
      requestStatus,
      resourceRoutes
    },
  } = useAppSelector(state => state);

  const getDrawerMenuComponent = () => dispatch(getMainMenu());

  return { resourceRoutes, requestStatus, getDrawerMenuComponent };

}