import React from "react";
import { Box, Stack } from "@mui/material";
import MiniDrawer from "widgets/drawer/drawer";

const Layout: React.FC<{children: React.ReactNode}>  = ({children}) => {
  
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toogleExpandDrawer = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }

  return (
    <Stack direction='row' sx={{width: '100%', height: '100%'}}>
      <MiniDrawer open={open} toogleExpandDrawer={toogleExpandDrawer} />
      <Stack 
        direction='column' 
        justifyContent='flex-start'
        alignItems='flex-start' 
        sx={{
          width: `calc(100% - ${open ? '261' : '81'}px)`,
          transition: `width ${open ? '225' : '195'}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`
        }}
      > 
        {children}
      </Stack>
    </Stack>
  )
}

export default Layout