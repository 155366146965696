import React from 'react';
import { TreeSelect } from 'antd';
import { Box } from '@mui/material';

import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import './style.css';
import { useFilter } from 'entities/filter';
import { useFormFields } from 'entities/form-field';

interface TreeSelectItemProps {
	value: string | number;
	title: string;
	children?: TreeSelectItemProps[];
}

interface TreeSelectComponentProps {
	componentName: string;
	items: TreeSelectItemProps[];
	placeholder?: string;
  name?: string;
  formId?: string;
	addictedBy?: string;
  mapping?: { [addictedValue: string]: TreeSelectItemProps[] };
}

export const TreeSelectComponent: React.FC<TreeSelectComponentProps> = ({
	componentName,
	formId,
	addictedBy,
  mapping, 
	items = [],
	name = '', 
  placeholder = '', 
}) => {

	const filter = useFilter();
  const formFields = useFormFields();

	const [addictedValue, setAddictedValue] = React.useState<string>("#");

	const [value, setValue] = React.useState<string | number>();
	const [inputValue, setInputValue] = React.useState<string>();
	const handleChange = (newValue: string | number, nodes: any[]) => {
		setInputValue(nodes.join());
		setValue(newValue);

		formFields.updateFormField({
      form_id: formId ?? "",
      name,
      value: newValue
    })
	}

	const getItems = React.useMemo(() => {
    if (addictedBy) {
      if (addictedValue && mapping) {
        return mapping[addictedValue] || [];
      } else {
        return [];
      }
    } else {
      return items;
    }
  }, [addictedValue])

	const disabled = React.useMemo(() => {
    if (addictedBy) {
      if (addictedValue) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [addictedValue])

	React.useEffect(() => {
    if (formId && name) {
      const fromFilter = filter.getFormFilterByName(formId, name)?.value;
      const fromFormFields = formFields.getFormFieldByName(name, formId)?.value;
      setValue(fromFormFields);
    }
  }, [formId, name, filter.filters, formFields.getFormFieldByName(name, formId || "")])

	React.useEffect(() => {
    if (formId && addictedBy) {
      // const fromFilter = filter.getFormFilterByName(formId, addictedBy)?.value;
      const fromFormFields = formFields.getFormFieldByName(addictedBy, formId)?.value;
      const newAddictedFieldValue = fromFormFields;
      setAddictedValue(newAddictedFieldValue);
      
    }
  }, [formId, addictedBy, formFields.getFormFieldByName(addictedBy ?? "", formId ?? "")])

	return (
		<Box sx={{ p: 1 }}>
			<TreeSelect
				data-form-id={formId}
				data-name={name}
				data-value={JSON.stringify(value)}
				data-label={placeholder}
				data-component={componentName}
				data-select={inputValue}
				value={value}
				onChange={handleChange}
				treeData={getItems}
				disabled={disabled}
				placeholder={placeholder}
				treeDefaultExpandAll={false}
				suffixIcon={<ArrowDropDownIcon />}
				allowClear={{clearIcon: <CloseIcon />}}
				className='tree-select'
				dropdownStyle={{
					maxHeight: 400,
					overflow: 'auto',
					zIndex: 1301
				}}
			/>
		</Box>
	)
}

export const buildTreeSelectComponent = (component: any) => (
	<TreeSelectComponent 
		componentName={component.component}
		items={component['items']}
		formId={component['form_id']}
    placeholder={component['placeholder']}
    name={component['name']}
		addictedBy={component['addicted_by']}
    mapping={component['mapping']}
	/>
)