import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import { MIcons } from "shared/ui/icon/checkbox/mui_icon";

interface IMappingItem {
  value:      string;
  text_color: string;
  bg_color:   string;
}

interface BadgeComponentProps {
  value?:         string | any;
  componentName:  string;
  textColor?:     string;
  bgColor?:       string;
  align?:         string;
  textSize?:      string;
  mapping?:       any;
  height?:        number | string;
  width?:         number | string;
  margin?:        number[];
  padding?:       number[];
  iconRight?:     any;
  iconLeft?:      any;
}

export const BadgeComponent: React.FC<BadgeComponentProps> = ({ 
  value, 
  componentName,
  mapping,
  textColor = 'black', 
  bgColor = 'transparent', 
  align = "left",
  textSize = "12px",
  height = 'unset',
  width = 'min-content',
  margin = [0, 0, 0, 0],
  padding = [4, 8, 4, 8],
  iconLeft = <></>,
  iconRight = <></>,
  }) => {

  const renderIcon = (icon: any) => icon ? icon.type.render() : null;
  const isMappingExist = Boolean(mapping);
  const mappingItem: IMappingItem = isMappingExist ? mapping[value] : {}

  return (
    // @ts-ignore
    <Stack 
      direction='row'
      justifyContent={align}
      alignItems='center'
      spacing={1}
    >
      {renderIcon(iconLeft)}
      {value 
        ? <Typography
            sx={{ 
              borderRadius: '6px',
              letterSpacing: '1px',
              lineHeight: '16px',
              componentName: componentName,
              fontSize: textSize,
              fontWeight: '500',
              color: isMappingExist ? mappingItem.text_color : textColor,
              backgroundColor: isMappingExist ? mappingItem.bg_color : bgColor,
              whiteSpace: 'nowrap',
              margin: margin.join('px ') + 'px',
              padding: padding.join('px ') + 'px',
              height: height,
              width: width,
            }}
          >
            {isMappingExist ? mappingItem.value : value}
          </Typography>
        : null
      }
      {renderIcon(iconRight)}
    </Stack>
  )
}

export const buildBadgeComponent = (component: any, mapping?: any) => (
  <BadgeComponent 
    componentName={component.component} 
    value={component['value']}
    mapping={mapping || component['mapping']}
    textColor={component['text_color']}
    bgColor={component['bg_color']}
    align={component['align']}
    textSize={component['text_size'] || '12px'}
    height={component['height']}
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
    // @ts-ignore
    iconRight={component['right_icon'] ? MIcons[component['right_icon']] : null}
    // @ts-ignore
    iconLeft={component['left_icon'] ? MIcons[component['left_icon']] : null}
  />
)