import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialMainMenuState, ResourceRoute } from "./state";
import { RequestStatus } from "shared/models/model";

const mainMenuComponentSlice = createSlice({
  name: 'main-menu-component',
  initialState: initialMainMenuState,
  reducers: {
    drawerMenuComponentRequest(state) {
      state.requestStatus.isLoading = true;
    },
    drawerMenuComponentRequestSuccess(state, action: PayloadAction<ResourceRoute[]>) {
      state.requestStatus.isLoading = false;
      state.requestStatus.error = '';
      state.resourceRoutes = action.payload;
      state.requestStatus.success = true;
    },
    drawerMenuComponentRequestError(state, action: PayloadAction<any>) {
      state.requestStatus.isLoading = false;
      state.requestStatus.success = false;
      state.requestStatus.error = action.payload;
    },
    drawerMenuComponentRequestRestore(state) {
      state.resourceRoutes = [];
      state.requestStatus = {} as RequestStatus;
    }
  }
});

export const mainMenuComponentActions = mainMenuComponentSlice.actions;
export const mainMenuComponentReducer = mainMenuComponentSlice.reducer;