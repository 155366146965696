import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MainMenuResourceRoute } from "shared/http/admin-api/model";
import { RequestStatus } from "shared/models/model";
import { initialAuthState } from "./state";
import { 
  loginReducers, 
  logoutReducers 
} from './reducers'

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    ...loginReducers,
    ...logoutReducers
  }
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;