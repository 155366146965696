import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as LogoIcon } from "../../shared/assets/logo.svg";
import { ReactComponent as NotFoundIcon } from "../../shared/assets/404-icon.svg";
import { ReactComponent as UpsWordIcon } from "../../shared/assets/ups-word-icon.svg";
import { ReactComponent as CrownIcon } from "../../shared/assets/crown-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { CustomButton, PrimaryButton } from "shared/ui/button";

import './style.css';

const NotFoundPage404 = () => {

  const navigation = useNavigate();

  const handleClickBackMain = () => navigation('/');

  return (
    <Stack
      className="page-error"
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={7}
      sx={{
        py: 5,
        width: '100%',
        height: '100%',
      }}
    >
      <Box className='logo'>
        <LogoIcon style={{width: '100%', height: '100%'}} />
      </Box>

      <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          className='container'
        >
          <UpsWordIcon />
          <NotFoundIcon />
      </Stack>
      <Typography
        fontWeight={400}
        textAlign='center'
        className='text'
      >
        Страница не существует <br/>или находится в разработке
      </Typography>
      <Box className="button">
        <CustomButton  onClick={handleClickBackMain}>
          Вернуться на главную
        </CustomButton>
      </Box>
    </Stack>
  )
}

export default NotFoundPage404