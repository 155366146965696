// import Link from "@mui/material/Link"
import { useLocation, useNavigate, Link } from "react-router-dom";

import './style.css';

interface LinkComponentProps {
  url: string;
  text: string;
  textForReplace: string;
  absolute: boolean;
  rowData?: any;
  align?: "right" | "left" | "center" | "inherit" | "justify" | undefined;
}

export const LinkComponent: React.FC<LinkComponentProps> = ({ url, text, textForReplace, absolute, rowData, align = 'center'}) => {
  const matchReplace = /\{\{(\w+)\}\}/;
  const location = useLocation();

  const getHref = () => {
    if (!rowData) return url;

    const templateField = url.match(matchReplace)?.at(0) || "";
    const nameField = url.match(matchReplace)?.at(-1) || "";
    
    if (templateField && nameField) {
      let href = url.replace(templateField, rowData[nameField]);
      return href;
    } else {
      return url;
    }
  }

  return (
    <Link 
      to={getHref()} 
      state={{id: 1, backUrl: `${location.pathname}${location.search}`}} 
      style={{
        display: 'inline-block', 
        textAlign: align, 
        width: '100%' 
      }}
    >
      {rowData ? rowData[text.match(matchReplace)?.at(-1) || ""] : text}
    </Link>
  )
}

export const  buildLinkComponent = (component: any, textForReplace?: string, align?: string, rowData?: any) => (
  <LinkComponent 
    url={component.url ?? "#"} 
    text={component.text ?? ""} 
    textForReplace={textForReplace ?? "#"} 
    rowData={rowData || {}}
    absolute={component.absolute == undefined ? true : component.absolute}
    align={align || component['align']}
  />
)

interface OuterLinkComponentProps {
  url: string;
  text: string;
  align?: "right" | "left" | "center" | "inherit" | "justify" | undefined;
}

export const OuterLinkComponent: React.FC<OuterLinkComponentProps> = ({ url, text, align = 'left'}) => {

  return (
    <Link 
      to={url} 
      target="_blank"
      className="outer-link"
      style={{
        textAlign: align
      }}
    >
      {text}
    </Link>
  )
}

export const  buildOuterLinkComponent = (component: any, align?: string) => (
  <OuterLinkComponent 
    url={component.url ?? "#"} 
    text={component.text ?? ""}
    align={align || component['align']}
  />
)