import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import React from "react";

interface CustomButtonProps {
  text: String,
  onClick: () => void
}

export const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: '#55B040',
  padding: '22px 127px',
  borderRadius: '45px',
  color: '#FFFFFF',
  fontSize: 18,
  fontWeight: 400,
  textTransform: 'none',
  transition: '250ms',
  '&:hover': {
    opacity: 0.7,
    backgroundColor: '#55B040',

  }
}));

export const PrimaryButton: React.FC<CustomButtonProps> = ({text, onClick}) => {
  return (
    <CustomButton 
      fullWidth
      onClick={onClick}
    >
      {text}
    </CustomButton>
  )
}