import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialAppState } from "./state";


export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setCurrentUrlTable(state, action: PayloadAction<string>) {
      state.currentUrlTable = action.payload;
    },
    setAutoUpdate(state, action: PayloadAction<boolean>) {
      state.autoUpdateEnable = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<number>) {
      state.rowsPerPage = action.payload;
    },
    addSignal(state, action: PayloadAction<{ to: string, action: string, args?: any }>) {
      state.signals[action.payload.to] = { ...action.payload };
    },
    clearSignal(state, action: PayloadAction<{ to: string }>) {
      state.signals[action.payload.to] = null;
    },
  }
})

export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;