
import React from 'react';
import { FormControlLabel, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import 'moment/locale/ru';
import { useApp } from 'entities/app';
import { useParams } from 'react-router-dom';
import { BpCheckedIcon, BpIcon } from 'shared/ui/icon/checkbox';
import HiddenColumnButton from './hidden-column-button';
import HiddenColumnPopover from './hidden-column-popover';

import './style.css';

interface TableSettingsProps {
  table: any;
}

const TableSettings: React.FC<TableSettingsProps>  = ({ table }) => {
  const app = useApp();

  const { serviceSource, tableSource } = useParams<{ serviceSource: string, tableSource: string }>();
  const [openFilter, setOpenFilter] = React.useState<HTMLButtonElement | null>(null);
  const [openHiddenColumn, setOpenHiddenColumn] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(openFilter);
  const id = open ? 'simple-popover' : undefined;
  const openPopoverHidenColumn = Boolean(openHiddenColumn);
  const idHiddeColumn = openPopoverHidenColumn ? 'simple-popover2' : undefined;

  const handleChangeAutoUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    app.setAppState({autoUpdateEnable: event.target.checked});
  };

  const handleHiddenColumnOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenHiddenColumn(event.currentTarget);
  };

  const handleHiddenColumnClose = () => {
    setOpenHiddenColumn(null);
  };

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ px: 4, py: 2, borderTop: '1px solid rgba(0, 0, 0, 0.12)'  }}>
      <FormControlLabel
        label='Автообновление' 
        sx={{
          '& span.MuiFormControlLabel-label': {
            fontSize: 16,
            color: '#5A5A5A'
          }
        }}
        control={
          <Checkbox 
            checked={app.autoUpdateEnable}
            onChange={handleChangeAutoUpdate}
            icon={<BpIcon />}
            checkedIcon={<BpCheckedIcon />}
          />
        }
      />

      <HiddenColumnButton
        isAnyColumnShow={table.isAllColumnsHide() && table.isSuccess}
        openPopoverHidenColumn={openPopoverHidenColumn}
        handleHiddenColumnOpen={handleHiddenColumnOpen}
      />

      <HiddenColumnPopover 
        idHiddeColumn={idHiddeColumn}
        openHiddenColumn={openHiddenColumn}
        openPopoverHidenColumn={openPopoverHidenColumn}
        handleHiddenColumnClose={handleHiddenColumnClose}
        changeAndSaveHiddenColumn={table.changeAndSaveHiddenColumn}
        changeAndSaveColumnsOrder={table.saveColumnOrderByIds}
        hiddenHeader={table.header}
        restoreHiddenColumns={table.restoreHiddenColumns}
        isAnyColumnHide={table.isAnyColumnHide()}
      />
    </Stack>
  );

  // return (
  //   <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: 3, }}>
  //     <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
  //       <Stack direction='row' justifyContent='flex-start' alignItems='center'>
  //         <FormControlLabel
  //           label='Автообновление' 
  //           sx={{
  //             '& span.MuiFormControlLabel-label': {
  //               fontSize: 16,
  //               color: '#5A5A5A'
  //             }
  //           }}
  //           control={
  //             <Checkbox 
  //               checked={app.autoUpdateEnable}
  //               onChange={handleChangeAutoUpdate}
  //               icon={<BpIcon />}
  //               checkedIcon={<BpCheckedIcon />}
  //             />
  //           }
  //         />
  //       </Stack>
        
  //       <FilterBadge badgeContent={Object.keys(filter.filters).length}>
  //         <Button 
  //           sx={{
  //             backgroundColor: open ? '#DDECFF' : '#4D76AD',
  //             color: open ? '#4D76AD' : '#FFFFFF',
  //             textTransform: 'none',
  //             fontSize: 16,
  //             border: 'none',
  //             '&:hover': {
  //               border: 'none',
  //               backgroundColor: open ? '#DDECFF' : '#4D76AD',
  //               color: open ? '#4D76AD' : '#FFFFFF',
  //             }
  //           }}
  //           onClick={handleFilterOpen}
  //           variant="outlined"
  //           endIcon={<FilterIcon className={classNames('btn-filter-icon', {'btn-filter-icon-active': open})}/>}
  //         >
  //           Фильтры
  //         </Button>
  //       </FilterBadge>

  //       <Stack direction='row' justifyContent='flex-start' alignItems='center' flexWrap='wrap' sx={{ width: '100%' }}>
  //         {Object.keys(filter.filters).map(filterName => (
  //           <Box sx={{ m: 1 }}>
  //             <Chip label={filter.getChipLabel(filterName)} onDelete={() => {
  //               let filtersToRemove: string[] = [];
  //               if (filterName == 'locationId') {
  //                 filtersToRemove = [filterName, 'storageId'];
  //               } else {
  //                 filtersToRemove = [filterName];
  //               }
  //               navigator(`/${serviceSource}/${tableSource}/1?${new URLSearchParams(filter.removeFilter(filter.filters, filtersToRemove))}`);
  //               table.getData(table.dataUrl, 10, 0, filter.removeFilter(filter.filters, filtersToRemove));
  //               filter.removeAndChangeFilters(filtersToRemove);
  //             }} />
  //           </Box>
  //         ))}
  //       </Stack>
  //     </Stack>

  //     <HiddenColumnButton
  //       isAnyColumnShow={table.isAllColumnsHide() && table.isSuccess}
  //       openPopoverHidenColumn={openPopoverHidenColumn}
  //       handleHiddenColumnOpen={handleHiddenColumnOpen}
  //     />

  //     <HiddenColumnPopover 
  //       idHiddeColumn={idHiddeColumn}
  //       openHiddenColumn={openHiddenColumn}
  //       openPopoverHidenColumn={openPopoverHidenColumn}
  //       handleHiddenColumnClose={handleHiddenColumnClose}
  //       changeAndSaveHiddenColumn={table.changeAndSaveHiddenColumn}
  //       changeAndSaveColumnsOrder={table.saveColumnOrderByIds}
  //       hiddenHeader={table.hiddenHeader}
  //       restoreHiddenColumns={table.restoreHiddenColumns}
  //       isAnyColumnHide={table.isAnyColumnHide()}
  //     />
      
      
  //     <Popover
  //       id={id}
  //       open={open}
  //       anchorEl={openFilter}
  //       onClose={handleFilterClose}
  //       anchorOrigin={{
  //         vertical: 'bottom',
  //         horizontal: 'left',
  //       }}
  //     >
  //       {renderServiceFilter()}
  //     </Popover>
  //   </Stack>
  // );
}

export default TableSettings;