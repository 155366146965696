import { Box } from "@mui/material";
import Button from "@mui/material/Button"

import './style.css';
import { MIcons } from "shared/ui/icon/checkbox/mui_icon";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export const BackButtonComponent: React.FC<{
  children?: React.ReactNode | string,
  componentName: string,
  size?: string,
  leftIcon?: any,
}> = ({ 
  componentName, 
  children, 
  size='medium', 
  leftIcon = <></> 
}) => {

  const location = useLocation();
  const navigator = useNavigate();

  const getBackUrl = (e: any) => {
    e.preventDefault();
    const backUrl = location?.state?.backUrl;
    const historyCount = window.history.length;
    
    if (historyCount <= 2) {
      navigator('/');
    } else {
      window.history.back();
    }
    // if (backUrl) {
    //   navigator(backUrl);
    // } else {
    //   if (historyCount <= 2) {
    //     navigator('/');
    //   } else {
    //     window.history.back();
    //   }
    // }
    
    // return location?.state?.backUrl || '/';
  }


  return (
    <Box className='back-button' sx={{ height: '100%' }}>
      {/* @ts-ignore */}
      <Link 
        onClick={getBackUrl}
        // to={getBackUrl()}
        style={{ 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textDecoration: 'none',
          height: '100%',
        }}
      >
        {leftIcon && leftIcon.type.render()}
        <p style={{ color: '#000000', marginLeft: '8px' }}>
          {children}
        </p>
      </Link>
    </Box>
  )
}

export const buildBackButtonComponent = (component: any) => (
  <BackButtonComponent 
    componentName={component.component}
    size={component['size']}
    // @ts-ignore
    leftIcon={component['left_icon'] ? MIcons[component['left_icon']] : null}
    children={component['value']}
  />
)