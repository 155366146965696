import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, List, ListItem } from '@mui/material';
import React from 'react';
import { buildComponents } from 'shared/utils/build';

interface CollapseComponentProps {
  children?: React.ReactNode[],
  componentName: string,
  label?: string;
}

export const CollapseComponent: React.FC<CollapseComponentProps> = ({ 
  children,
  componentName,
  label
}) => {

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(!open);

  return (
    <>
      <Button
        variant='text'
        startIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={handleClickOpen}
        sx={{
          color: '#000000',
          textTransform: 'none'
        }}
      >
        {label}
      </Button>
      <Collapse
        // @ts-ignore
        component={componentName}
        in={open}
        timeout='auto'
        unmountOnExit
      >
        {children}
      </Collapse>
    </>
  );
}

export const buildCollapseComponent = (component: any) => (
  <CollapseComponent 
    componentName={component.component}
    label={component['label']}
  >
    {component['content'] != undefined ? component['content'].map((c: any) => buildComponents(c)) : null}
  </CollapseComponent>
)