import { RequestStatus } from "shared/models/model";


export interface TableComponentModel {
    component:          string;
    id:                 string;
    data_url:           string;
    data_method:        string;
    rows_per_page:      number;
    columns:            Column[];
    pagination_enabled: boolean;
    enable_rows_count:  boolean;
}

export interface Column {
  name:       string;
  align:      string;
  value_type: ColumnType;
  title:      string;
  size:       string;
  left_icon?:  string;
  right_icon?: string;
  left_icon_color?:  string;
  right_icon_color?: string;
  sortable?:  boolean;
  hide?: boolean;
}
export interface ColumnType {
  component: string;
  content:   any[];
  url?:      string;
  text?:     string;
  absolute?: boolean;
  type?:     string;
  align?:    string;
  mapping?:  string;
}

export interface ValueTypeParams {
  pre: boolean;
}

interface ITableComponentState {
  tableComponent: TableComponentModel;
}

export const initialTableState: ITableComponentState = {
  tableComponent: {} as TableComponentModel,
}