import { List, ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { buildComponents } from 'shared/utils/build';

interface ListComponentProps {
  children?: React.ReactNode[],
  componentName: string,
  height?: number | string;
  width?: number | string;
  margin?: number[];
  padding?: number[];
}

export const ListComponent: React.FC<ListComponentProps> = ({ 
  children,
  componentName,
  height = '100%',
  width = 'unset',
  margin = [0, 0, 0, 0],
  padding = [0, 0, 0, 0]
}) => {
  return (
    <List
      // @ts-ignore
      component={componentName}
      sx={{
        margin: margin.join('px ') + 'px',
        padding: padding.join('px ') + 'px',
        height: height,
        width: width,
        overflowX: 'hidden',
      }}
    >
      {children}
    </List>
  );
}

export const buildListComponent = (component: any) => (
  <ListComponent 
    componentName={component.component}
    height={component['height']}
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
  >
    {component['content'] != undefined 
      ? component['content'].map((c: any) => 
        <ListItem>
          {buildComponents(c)}
        </ListItem>  
      ) 
      : null
    }
  </ListComponent>
)