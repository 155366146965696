import Box from "@mui/material/Box"
import { buildComponents } from "shared/utils/build"

export const PageComponent: React.FC<{children?: React.ReactNode, componentName: string}> = ({ children, componentName }) => {
  
  return (
    // @ts-ignore
    <Box 
      sx={{
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        overflow: 'auto',
        // px: 3
      }}
    >
      {children}
    </Box>
  )
}

export const buildPageComponent = (component: any) => (
  <PageComponent componentName={component.component}>
    {component['content'] != undefined ? component['content'].map((c: any) => buildComponents(c)) : null}
  </PageComponent>
)