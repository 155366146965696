import React, { useState } from "react";
import L from "leaflet";
import { TileLayer, FeatureGroup, MapContainer } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useRef } from "react";

import ReplayIcon from "@mui/icons-material/Replay";
import "leaflet-draw/dist/leaflet.draw.css";
import "./style.css";

import iconUrl from "shared/assets/marker-icon.png"
import shadowUrl from "shared/assets/marker-shadow.png"
import { useFormFields } from "entities/form-field";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";


interface LatLng {
  lat: number,
  lng: number
}
interface MapLayer {
  id: any;
  latlngs: LatLng[];
}

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconUrl,
  iconUrl: iconUrl,
  shadowUrl: shadowUrl,
});

export const MapComponent: React.FC<{
  componentName: string,
  name?: string,
  formId?: string,
  height?: string,
  label?: string,
  type?: string,
}> = ({
  componentName,
  formId,
  name,
  label,
  type,
  height = '200px'
}) => {
  
  const formFields = useFormFields();
  const [center, setCenter] = useState({ lat: 55.755811, lng: 37.617617 });
  const [mapLayers, setMapLayers] = useState<MapLayer[]>([]);
  const [isReplay, setIsReplay] = useState<boolean>(false);
  const [refreshMap, setRefreshMap] = useState<boolean>(false);
  // const map = new L.Map()
  // const [marker, setMarker] = useState<any | null>(null);

  const ZOOM_LEVEL = 12;
  const mapRef = useRef<any>();

  const handleReplayClick = () => {
    setMapLayers([]);
    setIsReplay(false);
    setRefreshMap(true);
  }

  const _onCreate = (e: any) => {
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayers((layers: any) => [
        ...layers,
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      ]);
    } else if (layerType === "marker") {
      const { _leaflet_id } = layer;

      setMapLayers([{ id: _leaflet_id, latlngs: [layer.getLatLng()] }])
      // setMarker({ id: _leaflet_id, latlngs: layer.getLatLng() });
    }
  };

  const _onEdited = (e: any) => {
    const {
      layers: { _layers },
    } = e;

    //@ts-ignore
    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, latlngs: { ...editing.latlngs[0] } }
            : l
        )
      );
    });
  };

  const _onDeleted = (e: any) => {
    const {
      layers: { _layers },
    } = e;

    //@ts-ignore
    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l: any) => l.id !== _leaflet_id));
    });
  };

  const getLatLngList = () => {
    if (mapLayers.length !== 0) {
      const lastMapLayer = mapLayers.at(-1);
      return lastMapLayer?.latlngs.map(coord => [coord.lat, coord.lng]);
    } else {
      return [];
    }
  }

  React.useEffect(() => {
    if (refreshMap) {
      setRefreshMap(false);
    }
  }, [refreshMap])

  React.useEffect(() => {
    if (mapLayers.length === 1) {
      setIsReplay(true);
    }

    formFields.updateFormField({
      form_id: formId || "",
      name: name || "",
      value: getLatLngList()
    });
  }, [mapLayers])

  return (  
    <>
      {label && <Typography>{label}</Typography>}
      <Box sx={{ width: '100%', height, position: 'relative' }}>
        {isReplay
          ? <Stack 
              direction='row'
              justifyContent='center'
              alignItems='center'
              sx={{
                position: 'absolute',
                width: '100%', 
                height: '100%',
                top: 0,
                left: 0,
                backgroundColor: '#000000d0',
                zIndex: 1001
              }}
            >
              <Button
                variant="outlined"
                startIcon={<ReplayIcon />}
                onClick={handleReplayClick}
              >
                Изменить?
              </Button>
            </Stack>
          : null
        }
        {refreshMap
          ? <CircularProgress />
          : <MapContainer 
              center={center} 
              zoom={ZOOM_LEVEL} 
              ref={mapRef}
              style={{ 
                width: '100%', 
                height: '100%',
              }}>
              <FeatureGroup>
                <EditControl
                  
                  position="topright"
                  onCreated={_onCreate}
                  onEdited={_onEdited}
                  onDeleted={_onDeleted}
                  draw={{
                    rectangle: false,
                    polyline: false,
                    circle: false,
                    circlemarker: false,
                    marker: type === 'point',
                    polygon: type === 'polygon',
                  }}
                />
              </FeatureGroup>

              <TileLayer
                url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=tmIg52UwTmPL57teNbPa"
              />
            </MapContainer>   
        }
      </Box>
    </>
  );
}

export const buildMapComponent = (component: any) => (
  <MapComponent 
    componentName={component.component}
    formId={component['form_id']}
    name={component['name']}
    height={component['height']}
    label={component['label']}
    type={component['type']}
  />
)