import React from 'react';
import { Modal, Stack, Typography } from "@mui/material";


interface ConditionModalProps {
  title: string,
  open: boolean,
  yesText?: string,
  noText?: string,
  icon?: React.ReactNode,
  onClose: () => void
  onYes: () => void
}

const ConditionModal: React.FC<ConditionModalProps> = ({ 
  open, 
  title,
  onClose, 
  onYes,
  icon = null,
  yesText = 'Да',
  noText = 'Нет',
}) => (
  <Modal
    open={open}
    onClose={onClose}
    onClick={(e: any) => e.stopPropagation()}
  >
    <Stack
      direction='column'
      justifyContent='flex-end'
      alignItems='center'
      sx={modalSxProps}  
    >
      {icon}
      <Typography textAlign='center' sx={titleSxProps}>
        {title}
      </Typography>
      <Stack sx={{ width: '100%', mt: 1 }} direction='row' justifyContent='center'>
        <Typography 
          fontSize={16} 
          textAlign='center' 
          color='#343434' 
          sx={noButtonSxProps}
          onClick={onClose}
        >
          {noText}
        </Typography>
        <Typography 
          fontSize={16} 
          textAlign='center' 
          sx={yesButtonSxProps}
          onClick={onYes}
        >
          {yesText}
        </Typography>
      </Stack>
    </Stack>
  </Modal>
)

const titleSxProps = {
  my: 3,
  px: 3
}

const noButtonSxProps = {
  padding: '20px 18px', 
  width: '100%',
  borderTop: '1px solid #E7E7E7',
  borderRight: '1px solid #E7E7E7',
  cursor: 'pointer',
  transition: '250ms',
  '&:hover': {
    opacity: '0.7'
  }
}

const yesButtonSxProps = {
  padding: '20px 18px', 
  width: '100%',
  borderTop: '1px solid #E7E7E7',
  cursor: 'pointer',
  color: '#F93D3D',
  transition: '250ms',
  borderBottomRightRadius: '8px',
  '&:hover': {
    backgroundColor: '#F93D3D',
    color: '#FFFFFF'
  }
}

const modalSxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: 520,
  maxHeight: 220,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  pt: 3,
}

export default ConditionModal;