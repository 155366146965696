import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFormField, initialFormFieldsState } from "./state";

const formFieldsSlice = createSlice({
  name: 'form-field-data',
  initialState: initialFormFieldsState,
  reducers: {
    setFormFields(state, action: PayloadAction<IFormField[]>) {
      state.fields = action.payload;
    },
    removeFormField(state, action: PayloadAction<IFormField>) {
      state.fields = state.fields.filter(f => !(f.name === action.payload.name && f.form_id === action.payload.form_id));
    },
    updateFormFieldByName(state, action: PayloadAction<IFormField>) {
      const index = state.fields.findIndex(f => f.name === action.payload.name && f.form_id === action.payload.form_id);
      if (index !== -1) {
        state.fields[index] = action.payload;
      } else {
        state.fields.push(action.payload);
      }
    },
  }
});

export const formFieldsActions = formFieldsSlice.actions;
export const formFieldsReducer = formFieldsSlice.reducer;