import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";

import './style.css';
import { buildComponents } from "shared/utils/build";
import { MIcons } from "shared/ui/icon/checkbox/mui_icon";
import { useApp } from "entities/app";
import { ISignal } from "entities/app/model/state";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const ModalComponent: React.FC<{
  children?: React.ReactNode | string,
  componentName: string,
  type?: string,
  size?: string,
  label?: string,
  padding?: string,
  leftIcon?: any,
  textColor?: string
}> = ({
  componentName, 
  children, 
  leftIcon,
  textColor = '#000000',
  label = '', 
  type = 'text', 
  size = 'medium', 
  padding = '0',
}) => {

  const app = useApp();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e?: any) => {
    if (e) e.stopPropagation();
    setOpen(false);
  };

  const handleSignal = (signal: ISignal) => {
    const { action, args } = signal;

    switch(action) {
      case 'close':
        handleClose();
        break
    }
  }

  React.useEffect(() => {
    const signal = app.signals[componentName];
    if (signal) {
      handleSignal(signal);
      app.clearSignal(componentName);
    }
  }, [app.signals[componentName]])

  return (  
    <>
      {label
          // @ts-ignore
        ? <Button
            fullWidth
            size={size}
            variant={type}
            className="modal-button"
            onClick={handleClickOpen}
            startIcon={leftIcon ? leftIcon.type.render() : null}
            sx={{ 
              fontSize: 12, 
              whiteSpace: 'nowrap', 
              lineHeight: 0, 
              color: textColor, 
              p: 2,
              '& .MuiSvgIcon-root': {
                color: textColor
              }
            }}
          >
            {label}
          </Button>
        : <IconButton 
            size="small" 
            className="modal-button"
            onClick={handleClickOpen}
            sx={{
              '& .MuiSvgIcon-root': {
                color: textColor
              }
            }}
          >
          {leftIcon ? leftIcon.type.render() : null}
        </IconButton>
      }
      <Dialog
        className="popover"
        open={open}
        onClose={handleClose}
        onClick={(e: any) => e.stopPropagation()}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 'unset'
          }
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          // @ts-ignore
          componentName={componentName}
          sx={{
            width: 'max-content'
          }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
}

export const buildModalComponent = (component: any, options?: any) => (
  <ModalComponent 
    componentName={component.component}
    padding={component['padding']}
    size={component['size']}
    type={component['type']}
    label={component['label']}
    textColor={component['text_color']}
    // @ts-ignore
    leftIcon={MIcons[component['left_icon']]}
  >
    {component['content'] != undefined ? component['content'].map((c: any) => buildComponents(c, options)) : (component['value'] ?? null)}
  </ModalComponent>
)