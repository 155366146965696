import { useAppDispatch, useAppSelector } from "app/store/lib";
import * as AdminService from "./api";

export const usePageComponent = () => {
  const dispatch = useAppDispatch();
  const {
    pageComponentReducer: {
      pageComponent,
      requestStatus
    }
  } = useAppSelector(state => state);

  const getPageComponent = (url: string ) => {
    dispatch(AdminService.getPageComponent(url));
  }

  return { getPageComponent, component: { ...pageComponent }, ...requestStatus };
}