import { Box, Stack, Typography, styled } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { buildComponents } from "shared/utils/build";

import { MIcons } from "shared/ui/icon/checkbox/mui_icon";

interface InlineKeyValueComponentProps {
  componentName: string;
  children?: React.ReactNode,
  title?: string;
  fontSize?: string;
  align?: string;
  iconRight?: string;
  iconLeft?: string;
  tooltip?: string[];
  tooltipTextType?: string;
}
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip 
    {...props} 
    classes={{ popper: className }} 
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -14],
            },
          },
        ],
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#49454F',
    padding: '12px 16px',
    boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
    fontSize: '12px',
    fontWeight: '400',
  },
}));

export const InlineKeyValueComponent: React.FC<InlineKeyValueComponentProps> = ({ 
  componentName, 
  title, 
  children,
  tooltip,
  tooltipTextType,
  fontSize = '14px',
  align = 'center',
  iconLeft = <></>,
  iconRight = <></>,
}) => {
  // @ts-ignore
  const { value } = children?.props || {};

  // @ts-ignore
  const renderIcon = (icon: any) => icon ? icon.type.render() : null;

  const renderTooltip = (icon: any) => (
    <HtmlTooltip 
      placement="bottom-start"
      title={
        tooltip ? (
          tooltipTextType == 'list' 
          ? <ul style={{ paddingLeft: '8px'}}>
            {tooltip.map(text =>
              <li style={{ lineHeight: '20px' }}>
                {text}
              </li>
            )}
          </ul>
          : tooltip.join(' ')
        ) : ''
      }
    >
      {icon}
    </HtmlTooltip>
  )

  return (
    <Box sx={{ '& .MuiSvgIcon-root': { width: 16, height: 16 } }}>
      <Stack
        direction='row'
        justifyContent={align}
        alignItems='flex-start'
        // spacing={1}
        sx={{
          color: '#888',
          fontSize: fontSize,
          fontWeight: 400,
          lineHeight: '20px',
          textTransform: 'uppercase',
          mb: 0.5,
          '& .MuiSvgIcon-root': {
            mt: 0.1,
            color: '#666666',
            width: 16,
            height: 16
          }
        }}
      >
        {renderTooltip(renderIcon(iconLeft))}
        <Typography 
          sx={{ 
            ml: iconLeft ? 0.5 : 0, 
            mr: iconRight ? 0.5 : 0,
            textAlign: align
          }}
        >
          {title}
        </Typography>
        {renderTooltip(renderIcon(iconRight))}
      </Stack>
      {value 
        ? <Box
          sx={{ 
            ml: iconLeft ? 2.5 : 0, 
            mr: iconRight ? 2.5 : 0,
          }}
        >
          {children}
        </Box>
        : <Typography
            color='#000'
            fontSize={16}
            fontWeight={400}
            align="center"
          >
            -
          </Typography>
        }
    </Box>
  );
}

export const buildInlineKeyValueComponent = (component: any) => (
  <InlineKeyValueComponent 
    componentName={component.component}
    title={component['title']}
    fontSize={component['font_size']}
    align={component['align']}
    // @ts-ignore
    iconRight={component['icon_right'] ? MIcons[component['icon_right']] : null}
    // @ts-ignore
    iconLeft={component['icon_left'] ? MIcons[component['icon_left']] : null}
    tooltip={component['tooltip']}
    tooltipTextType={component['tooltip_text_type']}
  >
    {component['value'] != undefined ? buildComponents(component['value']) : null}
  </InlineKeyValueComponent>
)