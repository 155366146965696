import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { buildComponents } from 'shared/utils/build';
import TabPanel from './tab-panel';
import { a11yProps } from './props';
import { Stack } from '@mui/material';
import { useBindNavigator } from 'shared/hooks/bind-navigator';
import { useLocationParams } from 'shared/hooks/params';
import { useFilter } from 'entities/filter';

interface TabComponentProps {
  component: string;
  id: number;
  label: string;
  content?: any[];
}

interface TabsContainerComponentProps {
  content?: any[];
  componentName: string;
  height?: number | string;
  width?: number | string;
  margin?: number[];
  padding?: number[];
}

export const TabsContainerComponent: React.FC<TabsContainerComponentProps> = ({ 
  componentName,
  content = [], 
  width = '100%',
  height = '100%',
  margin = [0, 0, 0, 0],
  padding = [0, 0, 0, 0]
}) => {
  const { addToNavigateBar,clearNavigatorBar } = useBindNavigator<any>();
  const { params } = useLocationParams<any>(window.location.search);
  const filter = useFilter();

  const [value, setValue] = React.useState<number>(params.tab ? parseInt(params.tab) - 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    clearNavigatorBar({
      page: 1,
      rows: params.rows,
      tab: newValue + 1,
    })
  };

  return (
    // @ts-ignore
    <Stack
      // @ts-ignore
      componentName={componentName}
      sx={{ 
        margin: margin.join('px ') + 'px',
        padding: padding.join('px ') + 'px',
        height: height,
        width: width
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
        >
          {content.map((tab: TabComponentProps) =>
            <Tab label={tab.label} {...a11yProps(tab.id)} />
          )}
        </Tabs>
      </Box>
      
      {content.map((tab: TabComponentProps) =>
        <TabPanel value={value} index={tab.id} >
          {tab['content'] != undefined ? tab['content'].map((c: any) => buildComponents(c)) : null}
        </TabPanel>
      )}
    </Stack>
  );
}

export const buildTabsContainerComponent = (component: any) => (
  <TabsContainerComponent 
    componentName={component.component}
    height={component['height']}
    width={component['width']}
    margin={component['margin']}
    padding={component['padding']}
    content={component['content']}
  />
)