import { PayloadAction } from "@reduxjs/toolkit";
import { LoginResponseModel } from "shared/http/keycloak-api/model";
import { RequestStatus } from "shared/models/model";
import { IAuthState } from "../state";


export const loginReducers = {
  loginRequest(state: IAuthState) {
    state.loginRequestStatus.isLoading = true;
  },
  loginRequestSuccess(state: IAuthState, action: PayloadAction<LoginResponseModel>) {
    state.loginRequestStatus.isLoading = false;
    state.loginRequestStatus.error = '';
    state.loginInfo = action.payload;
    state.loginRequestStatus.success = true;
  },
  loginRequestError(state: IAuthState, action: PayloadAction<any>) {
    state.loginRequestStatus.isLoading = false;
    state.loginRequestStatus.success = false;
    state.loginRequestStatus.error = action.payload;
  },
  loginRequestRestore(state: IAuthState) {
    state.loginRequestStatus = {} as RequestStatus;
  }
}