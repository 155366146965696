import axios, { AxiosError } from "axios";
import { refreshTokenRequest } from "./keycloak";
import { logoutReason } from "shared/constants/strings";
// import { setUnauthorized } from "shared/config/error";

const $api = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_PROXY_URL,
  withCredentials: false
});

$api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    config.headers = config.headers ?? {};
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

$api.interceptors.response.use(
  (res) => res,
  async (e: AxiosError) => {
    const isInternalServierError = e.response?.status.toString().startsWith('5');
    const requestType = e.config?.method;
    try {
      const originalRequest = e.config;
      // @ts-ignore
      if (e.response?.status === 403 && e.config && !e.config?._isRetry) {
        // @ts-ignore
        originalRequest._isRetry = true;
        try {
          await refreshTokenRequest();
          // @ts-ignore
          return $api.request(originalRequest);
        } catch (e) {
          localStorage.clear();
          localStorage.setItem('logout_reason', logoutReason);
          localStorage.setItem('nextPage', `${window.location.pathname}${window.location.search}`);
          window.location.assign(`/login?nextPage=${window.location.pathname}${window.location.search}`);
          console.log('NON AUTHORIZED');
        }
      } else if (isInternalServierError && requestType === 'get') {
        window.location.assign(`/service-error?requestPage=${window.location.href}`);
      }
    } catch (e) {
      // console.log(e);
    }
    
    
    return Promise.reject(e);
  }
);


export { $api };