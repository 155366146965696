import { RequestStatus } from "shared/models/model";

export interface PageComponentModel {
  component: string;
  title:     string;
  content:   any[];
  modals?:   any[];
}

interface IPageComponentState {
  pageComponent: PageComponentModel;
  requestStatus: RequestStatus
}

export const initialPageState: IPageComponentState = {
  pageComponent: {} as PageComponentModel,
  requestStatus: {} as RequestStatus
}